@import "theme/variables";
.root {
    border-radius: 30px;
    width: auto;
    min-width: 100px;
    padding: 10px;
    font-weight: 500;
    display: flex;
    height: 28px;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    &:not(.active) {
        border: 1px solid $greyLight;
        background: white;
        color: $textGrey;
    }
    &.active {
        background: $green;
        color: white;
    }
}
