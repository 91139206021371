@import "theme/variables";

.outer {
    border-radius: 4px;
    display: flex;
    flex-grow: 1;
    margin: 5px 0px;
    flex-direction: row;
    width: 100%;
    margin-right: 200px;

    .row {
        position: relative;
        padding-left: 10px;
        min-height: 50px;
        border-radius: 4px 0 0 4px;
        border: 1px solid $greyAccent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 20px);

        .field {
            flex-grow: 1;
            margin-right: 20px;

            input {
                padding: 8px 10px !important;
                min-height: 38px !important;
            }
        }
        .featureText {
            display: inline-block;
        }
        .switch {
            width: 160px;
            min-width: 160px;
            margin-right: 20px;
        }

        .trash {
            position: absolute;
            top: 5px;
            right: 5px;
            color: $red;
            z-index: 2;
            cursor: pointer;
        }
    }

    .dragHandler {
        min-height: 50px;
    }
}
@media screen and (min-width: 900px) {
    .outer .switch {
        width: 200px !important;
        min-width: 200px !important;
    }
}
