@import "theme/variables";

.numberField {
    margin: 0;
    margin-right: 10px;
    min-width: 50px !important;
    max-width: 100px !important;
    & input {
        height: 43px;
        padding: 6px 10px;
    }
}

.displayFlex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .filterButton {
        flex-grow: 1;
        z-index: 1;
        &:not(.active) {
            background-color: $bgMain !important;
            color: $textDark !important;
        }
        &.active,
        &:hover {
            background-color: $primary !important;
            color: white !important;
        }
    }
}
