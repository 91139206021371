@import "theme/mixins";
.textEllipse {
    margin: 0px;
    max-width: 100%;
    @include ellipseText();
}
.popupDiv {
    min-width: 50px;
    width: 50px;
    max-width: 100px;
}
.absenceSummaryPopup {
    display: flex;
    flex-direction: column;
    padding: 10px;
    > div {
        display: flex;
        width: 100%;
        text-align: end;
        span {
            width: 250px;
            text-align: start;
            font-weight: 600;
            margin-right: 10px;
        }
    }
}
