@font-face {
    font-family: "Kometa Unicase Book";
    src: url("../../assets/fonts/kometa/KometaUnicase-Book.eot");
    src: local("Kometa Unicase Book"), local("KometaUnicase-Book"),
        url("../../assets/fonts/kometa/KometaUnicase-Book.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Book.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Book.woff") format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Book.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Bold.eot");
    src: local("Kometa Bold"), local("Kometa-Bold"),
        url("../../assets/fonts/kometa/Kometa-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Bold.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Bold.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-HeavyItalic.eot");
    src: local("Kometa Heavy Italic"), local("Kometa-HeavyItalic"),
        url("../../assets/fonts/kometa/Kometa-HeavyItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-HeavyItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/Kometa-HeavyItalic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-HeavyItalic.ttf")
            format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-MediumItalic.eot");
    src: local("Kometa Medium Italic"), local("Kometa-MediumItalic"),
        url("../../assets/fonts/kometa/Kometa-MediumItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-MediumItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/Kometa-MediumItalic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-MediumItalic.ttf")
            format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Medium.eot");
    src: local("Kometa Medium"), local("Kometa-Medium"),
        url("../../assets/fonts/kometa/Kometa-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Medium.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Medium.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Kometa Unicase";
    src: url("../../assets/fonts/kometa/KometaUnicase-Bold.eot");
    src: local("Kometa Unicase Bold"), local("KometaUnicase-Bold"),
        url("../../assets/fonts/kometa/KometaUnicase-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Bold.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Bold.woff") format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Bold.ttf")
            format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Thin.eot");
    src: local("Kometa Thin"), local("Kometa-Thin"),
        url("../../assets/fonts/kometa/Kometa-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Thin.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Thin.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-BoldItalic.eot");
    src: local("Kometa Bold Italic"), local("Kometa-BoldItalic"),
        url("../../assets/fonts/kometa/Kometa-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-BoldItalic.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-BoldItalic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-BoldItalic.ttf")
            format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Kometa Unicase";
    src: url("../../assets/fonts/kometa/KometaUnicase-Regular.eot");
    src: local("Kometa Unicase Regular"), local("KometaUnicase-Regular"),
        url("../../assets/fonts/kometa/KometaUnicase-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Regular.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Regular.woff")
            format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Regular.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Heavy.eot");
    src: local("Kometa Heavy"), local("Kometa-Heavy"),
        url("../../assets/fonts/kometa/Kometa-Heavy.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Heavy.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Heavy.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Heavy.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Kometa Book";
    src: url("../../assets/fonts/kometa/Kometa-BookItalic.eot");
    src: local("Kometa Book Italic"), local("Kometa-BookItalic"),
        url("../../assets/fonts/kometa/Kometa-BookItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-BookItalic.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-BookItalic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-BookItalic.ttf")
            format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Kometa Unicase";
    src: url("../../assets/fonts/kometa/KometaUnicase-Medium.eot");
    src: local("Kometa Unicase Medium"), local("KometaUnicase-Medium"),
        url("../../assets/fonts/kometa/KometaUnicase-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Medium.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Medium.woff")
            format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Regular.eot");
    src: local("Kometa Regular"), local("Kometa-Regular"),
        url("../../assets/fonts/kometa/Kometa-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Regular.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Regular.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Kometa Unicase";
    src: url("../../assets/fonts/kometa/KometaUnicase-Thin.eot");
    src: local("Kometa Unicase Thin"), local("KometaUnicase-Thin"),
        url("../../assets/fonts/kometa/KometaUnicase-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Thin.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Thin.woff") format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Thin.ttf")
            format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Light.eot");
    src: local("Kometa Light"), local("Kometa-Light"),
        url("../../assets/fonts/kometa/Kometa-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Light.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Light.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-ThinItalic.eot");
    src: local("Kometa Thin Italic"), local("Kometa-ThinItalic"),
        url("../../assets/fonts/kometa/Kometa-ThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-ThinItalic.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-ThinItalic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-ThinItalic.ttf")
            format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Kometa Unicase";
    src: url("../../assets/fonts/kometa/KometaUnicase-Heavy.eot");
    src: local("Kometa Unicase Heavy"), local("KometaUnicase-Heavy"),
        url("../../assets/fonts/kometa/KometaUnicase-Heavy.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Heavy.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Heavy.woff") format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Heavy.ttf")
            format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-LightItalic.eot");
    src: local("Kometa Light Italic"), local("Kometa-LightItalic"),
        url("../../assets/fonts/kometa/Kometa-LightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-LightItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/Kometa-LightItalic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-LightItalic.ttf")
            format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Kometa Unicase";
    src: url("../../assets/fonts/kometa/KometaUnicase-Light.eot");
    src: local("Kometa Unicase Light"), local("KometaUnicase-Light"),
        url("../../assets/fonts/kometa/KometaUnicase-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/KometaUnicase-Light.woff2")
            format("woff2"),
        url("../../assets/fonts/kometa/KometaUnicase-Light.woff") format("woff"),
        url("../../assets/fonts/kometa/KometaUnicase-Light.ttf")
            format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Kometa";
    src: url("../../assets/fonts/kometa/Kometa-Italic.eot");
    src: local("Kometa Italic"), local("Kometa-Italic"),
        url("../../assets/fonts/kometa/Kometa-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Italic.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Italic.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Kometa Book";
    src: url("../../assets/fonts/kometa/Kometa-Book.eot");
    src: local("Kometa Book"), local("Kometa-Book"),
        url("../../assets/fonts/kometa/Kometa-Book.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/kometa/Kometa-Book.woff2") format("woff2"),
        url("../../assets/fonts/kometa/Kometa-Book.woff") format("woff"),
        url("../../assets/fonts/kometa/Kometa-Book.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
