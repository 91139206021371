@import "theme/variables";
.root {
    width: 352px;
    height: 162px;
    background: white;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid $greyLightest;
}
.title {
    display: flex;
    width: 100%;
    align-items: center;
    height: 60%;
    padding: 15px;
    background: $primary;
}
.account {
    width: 100%;
    display: flex;
    height: 40%;
    font-weight: normal;
    padding-left: 25px;
    color: $primaryDark;
    align-items: center;
    font-size: 0.8rem;
}
.avatarImg {
    width: 42px;
    height: 42px;
    object-fit: cover;
}
.userName {
    font-weight: bold;
    color: white;
    opacity: 0.9;
    margin-left: 10px;
}
