@import "theme/variables";
@import "theme/mixins";

.root {
    border-radius: 6px;
    border: 1px solid $borderGrey;
    overflow: hidden;
    margin: 10px 0px;
    .header {
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;
        background: $primary;
        color: white;
        font-size: 0.95rem;
        font-weight: 600;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        &.openedBorder {
            border-bottom: 1px solid $borderGrey;
        }
        &.collapsible {
            cursor: pointer;
            &:hover {
                background: $primaryDark;
            }
        }
    }
    .collapseToggleIcon {
        color: white !important;
    }
    .body {
        padding: 15px;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-bottom: 5px;
    }
}
