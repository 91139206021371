@import "theme/variables";

.bodyRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .bodyTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        h4 {
            font-size: 1.2rem;
        }
        > span {
            color: $red;
        }
    }
}
