@import "theme/variables";
@import "theme/mixins";
.item {
    max-width: 100%;
    background: transparent !important;
    font-family: Arial, sans-serif;
    padding: 0px;
    margin-right: 8px;
    margin-top: 0px !important;
    margin-bottom: 0px;
    cursor: pointer;
    border: none;
    &.active {
        color: $textDark !important;
    }
}
.notAllowed {
    cursor: not-allowed !important;
}
.itemInnerRoot {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    padding: 12px;
    padding-right: 10px;
    border-radius: 10px !important;
    display: flex;
    background-color: $messageChatMenuBg !important;
    &.active,
    &:hover,
    &:focus {
        &:not(.notAllowed) {
            background: white !important;
        }
    }
}
.clipIcon {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    object-fit: contain;
}
.content {
    flex-grow: 1;
    padding-left: 10px;
    max-width: calc(100% - 50px);
    div.contentRows {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;
        height: 100%;
        div.leftSection {
            display: flex;
            flex-direction: column;
            width: calc(100% - 60px);
        }

        div.rightSection {
            display: flex;
            flex-direction: column;
            max-width: 60px;
            align-items: center;
        }
    }
}
.avatar {
    .onlineStatusDiv {
        position: absolute;
        bottom: 0px;
        right: 5px;
        width: 8px;
        height: 8px;
        background-color: $darkGreen;
        border-radius: 50%;
    }
    .image {
        width: 48px;
        height: 48px;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: medium;
    }
}
.name {
    font-size: 1rem;
    font-weight: 600 !important;
    color: $textDark !important;
    margin: 0px;
    display: block !important;
    max-width: 100%;
    @include ellipseText();
    overflow: hidden;
    margin-right: 5px;
}
.lastMessage {
    max-width: 100%;
    display: block;
    font-size: 0.8rem;
    color: $greyChateau !important;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lastMessageTime {
    min-width: 60px;
    font-family: Arial, sans-serif;
    color: $greyChateau !important;
    text-align: end;
    font-size: 0.8rem;
}
.unreadMessageBadge {
    width: 35px;
    max-width: 100%;
    text-align: center !important;
    font-family: Arial, sans-serif;
    padding: 3px 5px;
    font-size: 0.8rem;
}
