@import "tippy.js/dist/tippy.css";

.tippy-custom-popover {
    display: flex;
    flex-direction: column;

    * {
        font-family: "Lato", sans-serif;
    }

    > div:last-child {
        flex-grow: 1;
    }

    .popover-ul {
        padding-left: 25px;
    }
}

.tippy-box[data-theme~="error"] {
    background-color: $red;
    color: white;

    > .tippy-arrow::before {
        color: $red;
    }
}
