@import "theme/variables";
@import "theme/mixins";
.root {
    padding: 10px 5px;
    margin-bottom: 10px;
    max-width: 100vw;
    [class*="app-page-title"] {
        padding: 0px !important;
        margin: 0px;
    }
}
@media screen and (min-width: 1240px) {
    .root {
        min-height: 85px;
    }
}
.container {
    flex-wrap: wrap;
    .leftSection {
        display: flex;
        align-items: center;
    }
}
.goBack {
    margin-right: 15px;
}

.headerIcon {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.headingSection {
    display: flex !important;
    align-items: center;
    > * {
        display: flex !important;
        &:last-child {
            margin: 0px !important;
            flex-grow: 1;
        }
    }
}
.iconClass {
    color: $primary;
}
@media screen and (max-width: 900px) {
    .root {
        padding-top: 0px;
        max-width: 100vw;
        @include overflowXOverlay();
        [class*="app-page-title"] {
            margin: 0px !important;
        }
    }
    .container {
        flex-direction: column;
        > div {
            &:not(:first-child) {
                margin-top: 5px;
            }
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}
