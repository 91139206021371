.splittedDate {
    display: flex;
    justify-content: space-between;
    label:first-child {
        flex-grow: 1;
        margin-right: 12px !important;
    }
    label:last-child {
        max-width: 70px !important;
    }
}
