@import "theme/variables";

.localeOuter {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    & > * {
        display: flex;
        align-items: center;
    }

    .localeItem {
        border: 1px solid $borderGrey;
        border-radius: 4px;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px !important;
        }
        cursor: pointer;
        padding: 10px;
        .localeName {
            padding-left: 5px;
            padding-right: 20px;
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
        }
        .isDefaultSwitch {
            width: 150px;
        }
        .useDefaultCheckbox {
            width: 150px;
        }
        .angleIcon {
            font-size: 1rem;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

@media screen and (min-width: 900px) {
    .localeOuter .localeItem .useDefaultCheckbox {
        width: 180px !important;
    }
}
