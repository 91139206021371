@import "theme/variables";
@import "theme/variables";
@import "theme/mixins";

$reactDatePickerTodayBg: $green;
$reactDatePickerActiveBg: $primary;
$reactDatePickerHoverBg: $primary;
$reactDatePickerHeaderHoverBg: $lightBlue;
$reactDatePickerOutsideMonthHoverBg: $blackHaze;

.react-datepicker-popper {
    z-index: 100 !important;
    .react-datepicker__year-dropdown--scrollable,
    .react-datepicker__month-dropdown--scrollable,
    .react-datepicker__month-year-dropdown--scrollable {
        @include overflowYOverlay();
    }
}
.react-datepicker {
    border-radius: 15px;
    padding: 4px;
    margin-top: 0px;

    &::after,
    &::before {
        // to hide arrow
        display: none !important;
    }
    > * {
        font-family: Arial, Helvetica, sans-serif;
    }
    .react-datepicker__day--outside-month:empty {
        user-select: none !important;
        pointer-events: none !important;
        background-color: white !important;
    }
    .react-datepicker__day--in-selecting-range {
        background-color: white;
        color: black;
    }
    .react-datepicker__day--in-range {
        // &.react-datepicker__day--outside-month {
        //     background-color: $reactDatePickerHeaderHoverBg;
        // }

        // &:not(.react-datepicker__day--outside-month) {
        background-color: $reactDatePickerHeaderHoverBg;
        color: black;
        &.react-datepicker__day--range-end,
        &.react-datepicker__day--range-start {
            color: white !important;
            background-color: $primaryDark;
        }
        // }
    }
    [class*="--today"] {
        background-color: $reactDatePickerTodayBg !important;
        color: white !important;
    }
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__day--keyboard-selected {
        &:not([class*="--selected"]) {
            &:not([class*="--today"]) {
                &:not([class*="--range-end"]) {
                    &:not([class*="--range-start"]) {
                        border: 1px solid $primaryDark;
                        margin-bottom: -1px;
                        &:not([class*="--in-range"]) {
                            background-color: white;
                            &:hover {
                                color: black !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .react-datepicker__navigation {
        height: 22px;
    }
    & > button.react-datepicker__navigation {
        border-radius: 50%;
        top: 4px;
        height: 34px;
        width: 34px;
        &:hover {
            background-color: $reactDatePickerHeaderHoverBg;
        }
        & > .react-datepicker__navigation-icon {
            line-height: 100%;
            right: 0;
            left: 0;
        }

        &.react-datepicker__navigation--previous {
            margin-left: 5px;
        }

        &.react-datepicker__navigation--next {
            margin-right: 5px;
        }
    }

    & .react-datepicker__month-container {
        .react-datepicker__header {
            background-color: white;
            border: none;
            padding-top: 0;
            margin: 2px;
            &.react-datepicker-year-header,
            .react-datepicker__current-month {
                user-select: none !important;
                margin: 0 40px;
                width: calc(100% - 80px);
                height: 34px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 20px;
                transition: all 0.2s;

                &:hover {
                    background-color: $reactDatePickerHeaderHoverBg;
                }
            }

            &.react-datepicker-year-header {
                padding: 0 !important;
            }

            //day picker year select
            .react-datepicker__year-dropdown {
                background-color: white;
                width: 100px;
                height: 200px;
                left: 50% !important;
                padding: 0px !important;
                transform: translateX(-50%);

                .react-datepicker__year-option {
                    height: 22px;
                    &:hover {
                        background-color: $reactDatePickerHoverBg;
                        color: white;
                    }
                    a.react-datepicker__navigation {
                        &.react-datepicker__navigation--years-upcoming::before {
                            text-indent: 0;
                            content: "\25B2";
                            position: absolute;
                            left: 10px;
                            top: 5px;
                            margin: auto;
                        }
                        &.react-datepicker__navigation--years-previous::before {
                            content: "\25BC";
                            text-indent: 0;
                            position: absolute;
                            left: 10px;
                            top: -3px;
                            margin: auto;
                        }
                    }

                    &.react-datepicker__year-option--selected_year {
                        background-color: $reactDatePickerHoverBg;
                        color: white !important;
                        font-weight: 700;
                    }
                }
            }

            .react-datepicker__day-names {
                width: 100%;

                .react-datepicker__day-name {
                    color: $greyLight;
                    cursor: default;

                    width: 34px;
                    margin: 2px;
                    padding: 4px;
                    object-fit: contain;
                    height: 34px;

                    font-size: 14px;
                }
            }
        }

        .react-datepicker__month {
            margin: 0;
            .react-datepicker__week {
                .react-datepicker__day {
                    width: 34px;
                    max-height: 34px;
                    margin: 2px;
                    padding: 4px;
                    object-fit: contain;
                    font-size: 14px;
                    border-radius: 50%;

                    transition: all 0.2s;

                    &.react-datepicker__day--keyboard-selected {
                        &:not(.react-datepicker__day--in-range) {
                            &:not(
                                    .react-datepicker__month-text--keyboard-selected
                                ) {
                                background-color: white;
                            }
                        }
                        color: black;
                        &.react-datepicker__day--disabled {
                            color: $greyAccent !important;
                        }
                    }

                    &.react-datepicker__day--selected {
                        &:not(.react-datepicker__day--outside-month) {
                            background-color: $reactDatePickerActiveBg;
                            color: white !important;
                            outline: none !important;
                            font-weight: 600;
                        }
                    }

                    &.react-datepicker__day--outside-month {
                        color: $greyAccent;

                        &:hover {
                            background-color: $reactDatePickerOutsideMonthHoverBg;
                        }
                    }

                    &:hover {
                        background-color: $reactDatePickerHoverBg;
                        &:not(.react-datepicker__day--outside-month) {
                            background-color: $reactDatePickerHoverBg !important;
                        }
                        color: white;
                    }
                }
            }

            //for month year view
            .react-datepicker__month-wrapper {
                display: flex;
                & .react-datepicker__month-text {
                    padding: 16px 0;
                    width: 62px;
                    border-radius: 50%;

                    &.react-datepicker__month-text--keyboard-selected {
                        color: black;
                        &.react-datepicker__month--disabled {
                            color: $greyAccent !important;
                        }
                    }

                    &.react-datepicker__month--selected {
                        background-color: $reactDatePickerActiveBg;
                        font-weight: 700;
                        color: white !important;
                    }

                    &:hover {
                        background-color: $reactDatePickerHoverBg;
                        color: white;
                    }
                }
            }
        }
    }

    & .react-datepicker__year--container {
        .react-datepicker__header {
            background-color: white;
            border: none;
            margin: 2px;
            margin: 0 40px;
            width: calc(100% - 80px);
            height: 34px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 20px;
            transition: all 0.2s;

            &:hover {
                background-color: $reactDatePickerHeaderHoverBg;
            }
        }

        .react-datepicker__year .react-datepicker__year-wrapper {
            .react-datepicker__year-text {
                padding: 5px 0;
                border-radius: 15px;

                &.react-datepicker__year-text--keyboard-selected {
                    color: black;
                    &.react-datepicker__year--disabled {
                        color: $greyAccent !important;
                    }
                }

                &.react-datepicker__year-text--selected {
                    background-color: $reactDatePickerActiveBg;
                    color: white !important;
                    font-weight: 700;
                }

                &:hover {
                    background-color: $reactDatePickerHoverBg;
                    color: white !important;
                }
            }
        }
    }

    & .react-datepicker__time-container {
        .react-datepicker__header {
            background-color: white;
            border: none;
            cursor: default;
            .react-datepicker-time__header {
                font-weight: 300;
            }
        }

        .react-datepicker__time .react-datepicker__time-list-item {
            border-radius: 15px;
            margin: 2px 0;

            &:hover {
                background-color: $reactDatePickerActiveBg !important;
                color: white;
            }

            &.react-datepicker__time-list-item--selected {
                background-color: $reactDatePickerActiveBg !important;
            }
        }
    }

    // //for date time view
    // & .react-datepicker__input-time-container {
    //     font-size: 14px;
    //     font-weight: 400;

    //     & .react-datepicker-time__input-container {
    //         width: 150px;

    //         & .react-datepicker-time__input {
    //             width: 100%;

    //             .react-datepicker-time__input {
    //                 padding: 5px;
    //                 border: 1px solid $textGrey;
    //                 border-radius: 5px;

    //                 input:focus-visible {
    //                     border: 1px solid $textGrey;
    //                 }

    //                 & > * {
    //                     background-color: red !important;
    //                 }
    //             }
    //         }
    //     }
    // }
}
