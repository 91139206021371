@import "theme/variables";
.btnBlue {
    background-color: $primary;
    &:hover,
    &:focus,
    &:active {
        background-color: $primary !important;
    }
    &:disabled {
        background-color: $blueDimmed;
    }
}

.btnRed {
    background-color: $red;
    border: 0px solid;
    &:hover,
    &:focus,
    &:active {
        background-color: $red !important;
    }
    &:disabled {
        background-color: $lightRed2;
    }
}

.btnTransparent {
    background-color: transparent;
    border: 0px solid;
    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important;
    }
    &:disabled {
        background-color: transparent;
    }
}
