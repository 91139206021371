@import "theme/variables";

.root {
}
.headerContent {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        max-width: 400px;
        flex-grow: 1;
        > div {
            width: 100%;
        }
    }
}
.tableOuter {
    tbody {
        tr {
            &.emptyRow {
                &,
                td {
                    cursor: default !important;
                    background-color: transparent !important;
                }
            }
        }
    }
}
@media screen and (min-width: 700px) and (max-width: 900px) {
    .headerContent {
        justify-content: center;
        width: 100%;
    }
}
@media screen and (max-width: 700px) {
    .headerContent {
        min-width: 600px;
    }
}
