@media screen and (min-height: 760px) {
    html,
    body {
        height: 100vh !important;
        overflow-y: hidden;
    }
}

// @media screen and (min-width: 500px) {
//     .app-main__inner {
//         .right-content {
//             max-width: calc(100% - 5px) !important;
//         }
//     }
// }
@media screen and (min-width: 990px) {
    .app-container:not(.closed-sidebar) {
        .app-main__outer:not(.full-width) {
            max-width: calc(100vw - $sideBarWidth);
        }
    }
    .fixed-sidebar.closed-sidebar:not(.sidebar-mobile-open)
        .app-header__logo
        .logo-src {
        display: none !important;
    }
}
@media screen and (max-width: 990px) {
    .fixed-sidebar.closed-sidebar .app-header__logo {
        width: 220px !important;
    }
}

@media screen and (min-width: 1300px) {
    html,
    body {
        width: 100vw !important;
        max-width: 100vw !important;
        overflow-x: hidden;
    }
}

@media screen and (min-width: 1400px) {
    body {
        .auth-container {
            height: 100vh;
            overflow: hidden;
        }
    }
}
