@import "theme/mixins";
@import "theme/variables";

.verticalBar {
    padding: 20px 0 12px 24px;
    margin-left: 24px;
    border-left: 1px solid $borderGrey;
}
[class*="header-mobile-open"] {
    [class*="app-header-left"] {
        flex-grow: 1;
        max-width: 60%;
    }
    [class*="app-header-right"] {
        min-width: 180px;
    }
    .mobileBusinessName {
        font-size: 1.2rem;
        margin-left: 15px;
        color: $textDark;
        @include ellipseText();
    }
}

@media screen and (max-width: 500px) {
    [class*="header-mobile-open"] {
        [class*="app-header-left"] {
            max-width: 200px !important;
        }
    }
}
@media screen and (max-width: 400px) {
    [class*="header-mobile-open"] {
        [class*="app-header-left"] {
            display: none !important;
        }
    }
}
