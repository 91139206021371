.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: auto;
    > * {
        width: 100%;
        height: 100%;
    }
}
