@import "theme/variables";
@import "theme/mixins";

.root {
    padding: 0px 4px;
    .badge {
        background-color: $primary;
        color: white;
        font-weight: 600;
        height: 35px;
        font-size: 0.8rem;
        padding: 4px 6px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.red {
            background-color: $errorLightRed;
        }
    }
}
.red {
    color: $red;
}
.unavailable {
    @include flexCenter();
}
.editItem {
    display: flex;
    padding: 0px 4px;
    justify-content: space-between;
    .shiftLabel {
        @include ellipseText();
        flex-grow: 1;
    }
    .shiftSelect {
        flex-grow: 1;
    }
    .icon {
        min-width: 20px;
        margin-left: 3px;
        font-size: 0.8rem;
        color: $primary;
        img {
            width: 12px;
            height: 12px;
        }
    }
}
