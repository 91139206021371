@import "theme/variables";

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    .title {
        font-weight: 800;
        font-size: 1.1rem;
        text-align: center;
    }
    .noRegistration {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $lightRed2;
        font-weight: 600;
        font-size: 1rem;
        text-align: center;
    }
    .chart {
        width: 100%;
        height: 400px;
    }
}
