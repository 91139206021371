@import "theme/variables";

.appContainerWithLessTopPadding {
    padding-top: 15px !important;
}
.disabledRow {
    background-color: $greyLightest2 !important;
}

// [class*="child-table-container"] {
//     .disabledRow {
//         background-color: $greyLighter !important;
//     }
// }
