@import "theme/variables";

.root {
}
.content {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.tableOuter {
    tbody {
        tr {
            &.emptyRow {
                &,
                td {
                    cursor: default !important;
                    background-color: transparent !important;
                }
            }
        }
    }
}
