@import "theme/variables";
.root {
    display: flex;
    flex-direction: column;
    .title {
        font-weight: bold;
        color: $textDark;
        font-size: 1.1rem;
        margin-bottom: 10px;
    }
    .flagsRoot {
        display: flex;
        flex-direction: column;
        > span {
            // display: block;
            margin-bottom: 8px;
        }
        > div:first-child {
            width: 100%;
        }
    }
}
.btn {
    width: 100px;
}
.switch {
    margin-bottom: 5px !important;
}

@media only screen and (max-width: 1620px) {
    .switch {
        height: 44px;
    }
}
