@import "theme/variables";
$rowHeight: 44px;
.root {
    .tableContainer {
        min-height: calc(100% - 50px) !important;
        border-width: 0px !important;
        overflow: initial !important;
        table.table {
            > tbody > tr {
                &:not(:first-child) {
                    &:not(.fillerRow) {
                        height: $rowHeight !important;
                    }
                }
            }
        }
    }
    .table {
        border: 1px solid $borderGrey !important;
        border-top-width: 0px !important ;
        border-bottom: 1.5px solid $borderGrey !important;
        border-bottom-width: 1.5px;
        background-color: white;
        height: 100%;
        > thead {
            > tr > th {
                border-top: 1px solid $borderGrey !important;
            }
        }
        th {
            padding-left: 0px;
            padding-right: 0px;
            text-align: center;
        }
        > tbody {
            > tr {
                height: $rowHeight !important;
                &:hover {
                    background-color: transparent !important;
                }
                &:first-child {
                    &,
                    > td:first-child {
                        height: 5px !important;
                    }
                }
                &,
                &:not(:first-child) {
                    > td {
                        &,
                        &:first-child {
                            border-left-width: 0px !important;
                            border-radius: 0px !important;
                        }
                        &:last-child {
                            border-right-width: 0px !important;
                        }
                    }
                }
                > td {
                    height: $rowHeight !important;
                    border-radius: 0px !important;
                    padding: 0px !important;
                    border-top: 0px solid transparent !important;
                    border-bottom: 0px solid transparent !important;
                    &:not(:last-child) {
                        border-right: 1px solid $borderGrey !important;
                    }
                }
            }
        }
        .headerWeekDay {
            font-size: 1rem !important;
            font-weight: 500;
            text-align: center;
        }
        .column {
            width: 200px;
            min-width: 200px !important;
        }
        tr {
            &:not(:first-child) {
                &.fillerRow {
                    height: 100% !important;
                    &.title {
                        height: 60px !important;
                    }
                }
            }
        }
    }
}
