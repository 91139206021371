.tooltipIcon {
    margin-left: 10px;
    width: 18px;
    height: 18px;
    object-fit: contain;
}
.notesBtn{
    flex-grow: 1;
    display: flex;
    justify-content: end;
    > button{
        height:45px;
        max-width: 180px;
        display: flex;
        justify-content: center;
        align-self: start;
    }
}