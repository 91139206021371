.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    padding-bottom: 1px;
}
.slick-track {
    position: relative;
    left: 0;
    top: 0;
    min-width: 100% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}
