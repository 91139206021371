@import "theme/variables";

.outer {
    display: flex;
    overflow: auto;
    flex-wrap: wrap;

    .createDiv {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 370px;

        min-width: 350px;
        border: 1px solid $borderGrey;
        margin-right: 15px;
        margin-bottom: 15px;

        .btn {
            width: 60px;
            height: 44px;
        }

        .text {
            margin-top: 15px;
            font-size: 15px;
            color: $textGrey;
        }
    }
}

@media screen and (max-width: 768px) {
    .createDiv {
        width: 100%;
        margin-right: 15px;
    }
}
