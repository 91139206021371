@import "theme/variables";
.cursorDefault {
    cursor: default !important;
}
.flexCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.root {
    display: flex;
}
.outerDiv {
    display: flex;
    cursor: default !important;
    width: 130px;
}
.dayType {
    align-self: flex-end;
    display: inline-flex;
    flex-grow: 1;
    margin-left: 15px;
}
.table {
    td {
        text-align: start;
        word-wrap: break-word;
    }
}
.familyInfoTable {
    td,
    th {
        padding-left: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 5px;
        font-size: 0.8rem;
        cursor: default !important;
    }
    td {
        text-align: left;
    }
}
.poper {
    max-height: 500px;
    overflow: auto;
}
.badge {
    text-align: center;
    width: 30px;
    height: 18px;
    color: white;
    display: table-cell;
    vertical-align: middle;
    margin-right: 2px;
}

.badgeBlue {
    background-color: $primary;
    border-radius: 4px;
}

.badgeGreen {
    background-color: $green;
    border-radius: 4px;
}

.badgeYellow {
    background-color: $yellowDark;
    border-radius: 4px;
}

.badgeRed {
    background-color: $red;
    border-radius: 4px;
}
