table.table {
    margin-bottom: 0px !important;
    th {
        color: black;
        .fas {
            margin-left: 2px;
        }
    }
}

.table.table-hover {
    tr {
        transition: height 0.4s, display 0.4s;
        height: 45px;
        &.collapsed-row {
            &,
            > td {
                padding: 0px !important;
                margin: 0px !important;
                height: 0px !important;
            }
        }
    }

    .table-hover {
        tr {
            &.no-border {
                &,
                td {
                    border-width: 0px !important;
                    border-top: 0px solid white !important;
                }
            }

            td {
                transition: height 0.4s, display 0.4s;
            }

            transition: height 0.4s, display 0.4s;
        }
    }
}

.collapsed-sub-table {
    table-layout: fixed;

    .multi-action-btn-cell {
        .multi-action-btn {
            align-items: center;
        }

        .dropdown-menu {
            left: 10px;
            top: 0px;
            position: relative !important;
        }
    }

    tr {
        th:first-child,
        td:first-child {
            width: 50px !important;
            max-width: 50px !important;
            min-width: 50px !important;
        }
    }

    &:not(.no-followup) {
        tr {
            &:not(:hover) {
                &:last-child {
                    background-color: transparent;
                }
            }
        }
    }
}
