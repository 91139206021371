@import "theme/variables";

.itemOuter {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    > * {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box {
        padding: 5px;
        margin: 0 15px;
    }
}
.textDiv {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    flex-grow: 1;
    padding-left: 10px;
    overflow: none;
    margin-right: 10px;
    > * {
        width: 100%;
    }
}
