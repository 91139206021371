@import "theme/variables";

.infiniteScroll {
    overflow: hidden !important;
}
.isActiveTh {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

.NameTh {
    padding: 0px !important;
    margin: 0px;
    padding-left: 20px !important;
}
