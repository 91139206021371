// for over-riding global main HTML tag styles
.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}
h1 {
    font-size: 30px;
}
h2 {
    font-size: 24px;
}
h3 {
    font-size: 16px;
}
h4 {
    font-size: 14px;
}
h5 {
    font-size: 12px;
}
h6 {
    font-size: 10px;
}
h3,
h4,
h5 {
    margin-top: 5px;
    font-weight: 600;
}

.text-danger {
    color: $errorLightRed !important;
}
