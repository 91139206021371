@import "theme/variables";

.headerRightSection {
    display: flex;
    align-items: center;
}

.searchField {
    min-width: 150px;
    width: 300px;
    margin-right: 50px;
}

.createBtn {
    a {
        background-color: $primary;
    }
}

.tabs {
    background-color: transparent !important;
}
.tabButton {
    margin-right: 15px;
    height: 46px;
    border: 1px solid $blackHaze !important;
    font-size: 1rem;
    font-weight: 600;
    &.btnActive {
        background-color: $primary !important;
        border-color: $primary !important;
    }
}

@media screen and (max-width: 1400px) {
    .headerRightSection {
        max-width: 375px !important;
        .searchField {
            margin-right: 15px !important;
        }
    }
}

@media screen and (max-width: 1700px) {
    .tabs {
        max-width: 575px;
    }
    .headerRightSection {
        max-width: 430px;
        .searchField {
            margin-right: 20px;
        }
    }
}
