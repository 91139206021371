@import "theme/variables";
@import "theme/mixins";

.header {
    border-bottom: 1px solid $greyLightest !important;
    @include box-shadow(0, 1.5px, 15px, $greyLightest);
    align-items: center;
    width: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back {
    }
    .title {
        width: 100%;
        cursor: default;
        color: $textDark;
        vertical-align: middle;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .actionMenus {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
