@import "theme/mixins";

.textEllipse {
    margin: 0px;
    max-width: 350px;
    margin-bottom: -5px;
    @include ellipseText();
}
.button {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    height: 28px;
    max-width: 200px !important;
    span.text {
        margin-left: 2px;
        flex-grow: 1;
        text-align: center;
    }
    &:hover {
        color: white;
        * {
            color: white !important;
        }
    }
}
@media screen and (min-width: 2000px) {
    .textEllipse {
        max-width: 600px;
    }
}
