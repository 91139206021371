@import "theme/variables";
.explanation {
    font-size: 0.9;
    background-color: white;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    color: $textDark;
}
.title {
    font-size: 1.2rem;
    font-weight: 600;
    background-color: white;
    width: 100%;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
