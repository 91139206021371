@import "theme/mixins";

table.childTable > tbody > tr > td:last-child {
    border-right: 15px solid white !important;
}

.textEllipse {
    margin: 0px;
    @include ellipseText();
}
.avatar {
    height: 50px;
    padding: 0px 5px;
    img {
        height: 100%;
        width: 50px;
        object-fit: cover;
        object-position: top;
    }
}
