.linkItem {
    display: flex;
    align-items: center;

    img,
    svg,
    i {
        width: 25px;
        left: 10px;
        position: absolute;
        top: 19%;
        font-size: 1.3rem;
        opacity: 0.3;
    }
    img {
        width: 21px !important;
        height: 21px;
        object-fit: contain;
    }
}
