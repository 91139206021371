.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > :first-child {
        flex-grow: 1;
    }
}
.navHeight {
    max-height: 100%;
}
