@import "theme/variables";

.createBtn {
    min-width: 250px;
}

.messageMain {
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
    .cardStyling {
        position: relative;
        height: fit-content;
        min-height: 200px;
        flex-grow: 1;
        min-width: calc(100% - 400px);
        overflow: auto;
    }
}

@media only screen and (max-width: 800px) {
    .messageMain {
        flex-direction: column;
    }
}
@media only screen and (max-width: 800px) {
    .messageMain .cardStyling {
        min-height: 350px;
    }
}
