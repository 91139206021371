@import "./animations";
@import "./mixins";
@import "./variables";
@import "./typography/index";
@import "./partials/base";
@import "./achitectUi/theme";

body,
html {
    min-height: 100vh !important;
    height: initial;
    overflow: hidden;
    -webkit-tap-highlight-color: $yellow !important;
    img {
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
    }
}

* {
    font-family: arial;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}
* {
    scrollbar-color: $greyAccent transparent;
    scrollbar-width: thin;
}

::-moz-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $greyAccent;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
label.error,
span.error {
    color: $errorRed !important;
}
.btn:disabled {
    cursor: not-allowed;
}

body .disabled {
    &,
    .fa,
    .metismenu-icon,
    svg {
        cursor: not-allowed !important;
        &:not(button) {
            color: $greyAccent !important;
        }
    }
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    cursor: not-allowed !important;
}

.green-row {
    background-color: $lightGreen !important;
}
.red-row {
    background-color: $lightestRed !important;
}

.pdf-container {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: Max(500px, calc(100vh - 290px));

    &.pdf-without-header {
        min-height: Max(500px, calc(100vh - 110px)) !important;
    }

    .pdf-preview-iframe {
        flex-grow: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;

        img {
            max-width: 100px;
            max-height: 100px;
        }
    }
}
.embed-container {
    // not working properly from react
    --video--width: 1300;
    --video--height: 730;

    position: relative;
    padding-bottom: calc(
        var(--video--height) / var(--video--width) * 100%
    ); /* 56.156% */
    overflow: hidden;
    max-width: 100%;
    background: black;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.ellipsis-text {
    &,
    span,
    p {
        &:not(td, th, tr) {
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            word-break: break-all;

            @supports (-webkit-line-clamp: 2) {
                white-space: initial !important;
                -webkit-line-clamp: 2;
                display: -webkit-box !important;
                -webkit-box-orient: vertical !important;
            }
        }
    }
    &.single-line {
        -webkit-line-clamp: 1 !important;
        white-space: nowrap !important;
        span,
        p {
            -webkit-line-clamp: 1 !important;
            white-space: initial !important;
        }
    }
}

.bgBlue {
    background-color: $lightBlueDark;
    color: black;
}

.bgOrange {
    background-color: $yellowLight;
    color: black;
}
.color-blue {
    color: $primary !important;
}
.color-orange {
    color: $orange !important;
}
.color-red {
    color: $red !important;
}

.color-green {
    color: $darkGreen !important;
}

.color-grey {
    color: $darkGrey !important;
}

.transparent-dialog {
    .modal-content {
        background: transparent !important;
        border: 0px;
    }

    .modal-body {
        box-shadow: 0px 0px transparent;
    }

    .modal-close {
        color: white;
        font-size: 2rem;
        opacity: 1;
    }
}

a {
    font-weight: 400;
    text-decoration: none;
    line-height: 1.5;
    text-decoration-line: none !important;
}

@import "./partials/tooltip";
@import "./partials/table";
@import "./partials/buttons";
@import "./partials/slick";
@import "./partials/bootstrap-datetimepicker";
@import "./form/form";
@import "./partials/tinymce";
@import "timePicker";
@import "./partials/fullCalendar";
@import "./mediaQueries"; // should be at END
