@import "theme/variables";
.root {
    display: flex;
    position: relative;
    justify-content: center;
    padding: 0px 10px;
}
.icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    object-fit: contain;
}
.hiddenInput {
    width: 1px;
    opacity: 0;
    z-index: -1;
    position: absolute;
}
.button {
    width: 250px;
    max-width: 250px;
    padding: 10px 20px;
    font-size: 1.05rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: $Onahau;
    color: $veniceBlue;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    border: 0px !important;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none !important;
}
