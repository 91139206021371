@import "theme/variables";
@import "theme/mixins";

.root {
    display: flex;
    flex-direction: column;
    .title {
        height: 60px;
        min-height: 60px;
        &:first-child {
            // to handle container top padding
            min-height: 65px;
            height: 65px;
        }
        display: flex;
        color: $textDark;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
    }
    .item {
        display: flex;
        min-height: 40px;
        max-height: 40px !important;
        border-radius: 8px;
        background-color: white;
        padding: 8px 12px;
        margin: 2px 0px;
        justify-content: space-between;
        align-items: center;
        .name {
            flex-grow: 1;
            @include ellipseText();
            color: $textDark;
        }
        .hours {
            min-width: 85px;
            width: 85px;
            padding: 1px 5px;
            border-radius: 4px;
            margin-left: 5px;
            font-weight: 800;
            color: white;
            text-align: end;
            background-color: $createGreen;
            &.red {
                background-color: $lightRed2 !important;
            }
        }
    }
}
