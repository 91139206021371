@import "theme/variables";
.root {
    display: flex;
    flex-direction: column;
    .title {
        font-weight: bold;
        color: $textDark;
        font-size: 1.1rem;
        margin-bottom: 10px;
    }
    .flagsRoot {
        display: flex;
        flex-direction: column;
    }
}
