@import "theme/variables";
.root {
}

.headingSection {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    margin-right: 10px !important;
    > * {
        display: flex !important;
        &:last-child {
            margin: 0px !important;
            flex-grow: 1;
            text-align: left;
        }
    }
    .headerIconContainer {
        .headerIcon {
            margin-left: 1px;
            width: 25px;
            height: 25px;
            object-fit: contain;
        }
    }
}
.headerContent {
    display: flex;
    flex-grow: 1;
    align-items: center;
    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
    }
    .timerContainer {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        .border {
            width: 1px;
            height: 40px;
            background-color: $greyLightest;
            margin: 8px 20px 8px 10px;
        }
    }
}
.tabs {
    min-width: 400px;
    justify-content: flex-end;
    background-color: transparent !important;
}
.tabButton {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    margin-right: 15px;
    height: 46px;
    border: 1px solid $blackHaze !important;
    font-size: 1rem;
    font-weight: 600;
}
.btnActive {
    background-color: $primary !important;
    border-width: 0px !important;
}

.refreshButton {
    background-color: $primary;
    border-radius: 50%;
    height: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 50px;
    cursor: pointer;
    img {
        width: 26px;
        height: 26px;
        object-fit: contain;
    }
}
.progressDiv {
    width: 70px;
    height: 60px;
}

@media screen and (min-width: 1431px) {
    .headerContentGrow {
        min-width: 520px;
    }
}
@media screen and (max-width: 1430px) {
    .headerContent {
        min-height: 70px;
        .timerContainer .border {
            margin: 8px 15px !important;
        }
    }
    .root {
        min-height: 175px;
        [class*="app-page-title"]
            [class*="page-title-wrapper"]
            > div:first-child {
            flex-direction: column !important;
            > div {
                &:not(:first-child) {
                    margin-top: 5px;
                }
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .root {
        min-height: 160px;
    }
}
