@import "theme/variables";

.root {
    margin: 5px;
    h4 {
        margin: 0px !important;
        width: 100%;
        vertical-align: center;
        text-align: center;
        color: $greyLighter;
    }
}
