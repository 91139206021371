@import "theme/mixins";

.root {
    @include flexCenter();
    .icon {
        cursor: pointer;
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
}
