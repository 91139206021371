@import "theme/variables";
.image {
    width: 70px;
    height: 70px;
    &:hover {
        cursor: pointer;
    }
}
.updateImage {
    background-color: $lightBlue;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: 80px;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}
