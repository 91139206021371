@import "theme/variables";

.root {
    .headerContent {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
@media screen and (max-width: 900px) {
    .root .tabs .content {
        max-height: calc(100vh - 280px);
        min-height: calc(100vh - 280px);
        &.withoutNavigation {
            max-height: calc(100vh - 222px);
            min-height: calc(100vh - 222px);
        }
    }
}
