@import "theme/variables";
.playButton {
    margin: auto 0;
    display: flex;
    align-items: center;
    height: 20px;
    i,
    svg {
        color: $primary;
        font-size: 0.6rem;
        vertical-align: middle;
    }
    &.green {
        color: $green;
        i,
        svg {
            color: $green !important;
        }
    }
}
.container {
    min-width: 200px;
}

.textPrimary {
    color: $primary !important;
}
.textOrange {
    color: $orange !important;
}
