.tooltip {
    display: inline-flex;
    align-items: center;
}
.tooltipIcon {
    margin-left: auto;
    width: 20px !important;
    height: 20px !important;
    object-fit: contain;
    text-align: end;
}
