@import "theme/variables";
@import "theme/mixins";

.root {
    display: flex;
    flex-direction: column;
}

.displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
