@import "theme/variables";
.select {
    width: 100%;
    max-width: 450px;
}
.lineTd {
    width: 1px;
    height: 100%;
    max-width: 1px;
    min-width: 1px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    div {
        width: 1px;
        background-color: $greyLightest;
        padding-bottom: 55px;
    }
}
.tbWidth {
    max-width: 200px;
    min-width: 200px;
    width: 200px;
}
