@import "theme/variables";

.sideMenu {
    height: calc(100vh - 60px);
    width: 25%;
    position: relative;
    .animated {
        height: 100%;
        background: white;
        border-left: 1px solid $greyLighter;
    }
    .closeBtn {
        position: absolute;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-color: $greyLighter;
        right: 10px;
        img {
            width: 15px;
            height: 15px;
            object-fit: contain;
        }
    }
}
.header {
    width: 100%;
    background-color: $greyLightest2 !important;
    height: 30px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-style: italic;
    font-size: 1.2rem;
    border-top: 1px solid $greyLighter;
    border-bottom: 1px solid $greyLighter;
}

.overFlowContainer {
    max-height: calc(50% - 30px);
    overflow: auto;
}
