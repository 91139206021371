@import "theme/variables";
.infiniteScroll {
    overflow: hidden !important;
    max-height: calc(100vh - 215px) !important;
    height: calc(100vh - 215px) !important;
}
.rowList{
    max-height: calc(100vh - 365px) !important;
}
.dummyRow{
    border-top: 1px solid $greyLightest;
    td{
        padding: 0px;
    }
}
.root {
    display: flex;
    flex-direction: column;
}
.content {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
