.splittedDate {
    display: flex;
    justify-content: space-between;
    > div {
        &:first-child {
            flex-grow: 1;
            margin-right: 12px !important;
        }
        &:last-child {
            max-width: 85px !important;
            min-width: 85px !important;
        }
    }
}
