$placeholderPanelWidth: 300px;
$placeholderPanelWidthLg: 400px;
//-----------------------------------------------------
.tox-tinymce {
    border-radius: 4px !important;
}
.tox-sidebar-wrap > .tox-sidebar > .tox-sidebar__slider {
    width: $placeholderPanelWidth;
}
.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
    width: auto !important;
    padding-right: 10px;
}
.tox-collection__item-icon {
    display: none !important;
}
.tox-toolbar__group button[title="Fonts"].tox-tbtn.tox-tbtn--select {
    min-width: 150px;
    max-width: 150px;
    justify-content: space-between;
}

.custom-content-lock {
    cursor: not-allowed !important;
}

.right-editor-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: 1px solid $greyAccent !important;
    width: $placeholderPanelWidth !important;
    height: min-content !important;
    max-height: 100% !important;
    .title {
        border-bottom: 1px solid $greyAccent;
        font-size: 1.1rem;
        padding: 5px;
    }
    .vertical-scroll-container {
        display: flex;
        flex-direction: column;
        min-height: 50px;
        overflow-y: auto;
        flex-grow: 1;
    }
    .toggle-btns-row {
        display: flex;
        flex-flow: row wrap;
        border-bottom: 1px solid $greyAccent;
        padding: 5px;
        align-items: center;
        .type-toggle-btn {
            padding: 5px !important;
            border-radius: 25px !important;
        }
    }
    .placeholders-sidebar-container {
        padding: 5px;
        padding-right: 0px;
        overflow-x: hidden;
        display: flex;
        flex-flow: row wrap;
        overflow-y: auto;
        justify-content: center;
        align-items: flex-start;
    }
    .placeholder-chip {
        border-radius: 4px;
        padding: 2px 4px;
        flex-grow: 1;
        text-align: center;
        margin: 10px 4px;
        border: 1px solid $primaryDark !important;
        color: $primaryDark;
        line-height: 1;
        &:hover,
        &:checked,
        &.active {
            background-color: $primaryDark !important;
            color: white;
        }
    }
}
.tox-edit-area__iframe .mce-drag-container {
    display: none !important;
}
body #tinymce .placeholder-tag {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
@media screen and (min-width: 1300px) {
    body .right-editor-panel {
        width: $placeholderPanelWidthLg !important;
    }
    body .tox-sidebar-wrap > .tox-sidebar > .tox-sidebar__slider {
        width: $placeholderPanelWidthLg;
    }
}
