.root {
    width: 100%;
    display: flex;
    position: relative; // for loader overlay
    flex-grow: 1;
    iframe {
        width: 100%;
        border: 0px solid transparent !important;
        border-radius: 6px !important;
        outline: none !important;
    }
}
