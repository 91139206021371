@import "theme/variables";
.buttonDiv {
    // width: 225px;
    min-width: 240px;
    max-width: 320px;
    height: 50px;
    a,
    button {
        cursor: pointer;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        padding: 12px 40px;
        align-items: center;
        justify-content: center;
        display: flex;
        height: auto;
        font-size: 1.05rem;
        border-radius: 30px;
        text-decoration: none;
        color: white !important;
        background-color: $createGreen;
        border-width: 0px;
        &.disabled {
            cursor: not-allowed !important;
            opacity: 0.6;
        }
    }
}

.btnImage {
    height: 16px;
    width: 16px;
    object-fit: contain;
    margin-left: 10px;
}
