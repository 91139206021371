@import "theme/variables";

.header {
}
.buttons {
    display: flex;
    .pdfIcon {
        width: 40px;
        height: auto;
        cursor: pointer;
        object-fit: contain;
        margin-right: 10px;
    }
    .headerBtn {
        max-width: 150px;
        min-width: 150px;
        margin-left: 10px;
        &.styled {
            a,
            button {
                color: $textDark !important;
                background-color: transparent !important;
                border: 1px solid $greyLightest !important;
                &:hover,
                &:active {
                    &:not(:disabled) {
                        background-color: $primary !important;
                        color: white !important;
                    }
                }
            }
        }
        &.largeImg {
            img {
                width: 21px;
                height: 21px;
                margin-right: -5px;
            }
        }
    }
}
