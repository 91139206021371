@import "theme/variables";

.menuCard {
    border-radius: 20px !important;
}
.menuItem {
    margin-top: 2px;
    font-family: "Arial", sans-serif;
    font-size: 1rem !important;
    font-weight: 500;
    color: $navFontColor !important;
    display: flex;
    align-items: center;
    cursor: pointer !important;
    border-radius: 12px;
    // text-transform: capitalize !important;
    padding: 5px 18px;
    &:hover,
    &:focus,
    &:active {
        // font-weight: 600;
        background-color: $Onahau !important;
        background: $Onahau !important;
    }
    &,
    & > :first-child {
        word-break: break-word;
        white-space: normal;
    }
}
