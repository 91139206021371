@import "theme/variables";
.toggleIcon {
    cursor: pointer;
    text-align: right;
    .icon {
        color: $textGrey;
        transition: all 0.1s ease;
        &.rotate {
            transform: rotate(180deg);
        }
    }
}
