@import "theme/variables";
.modalFooterRightButtons {
    display: flex;
    justify-content: flex-end;
    > button:last-child {
        margin-left: 15px;
    }
}
.modalForm {
    textarea {
        resize: none;
    }
}
.checkBoxRow {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .switch {
        margin-left: 10px;
        color: $textDark;
    }
}
.header {
    margin-top: -6px;
    margin-bottom: -6px;
    display: flex;
    flex-direction: column;
    .title {
        font-size: 20px !important;
        font-weight: bold;
    }
    .writer {
        display: flex;
        height: 23px;
        font-size: 15px !important;
        color: $textGrey;
        font-weight: normal !important;
    }
}
.label {
    color: $textDark;
}

.receiptsSelectors {
    display: flex;
    flex-direction: column;
    margin: 0px !important;
    padding: 0px !important;
    > * {
        &:first-child {
            padding-left: 0px !important;
        }
        &:last-child {
            padding-right: 0px !important;
        }
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 20px;
    font-size: 1rem !important;
    button {
        font-size: 0.9rem !important;
    }
    .saveBtn {
        border-radius: 6px;
        padding: 10px 30px;
        text-align: center;
        text-transform: none;
        min-width: 135px;
        width: 135px;
    }

    .cancel {
        background-color: transparent !important;
        margin-right: 35px;
        border-width: 0px !important;
        color: $greyChateau !important;
        cursor: pointer;
    }
}

.bottomRow {
    display: flex;
    > div {
        padding-right: 15px;
        &.filesView {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-height: 350px;
            height: 100%;
            overflow: hidden;
            padding-left: 15px;
            padding-right: 0 !important;
        }
        width: 50%;
    }
}

@media only screen and (max-width: 800px) {
    .bottomRow {
        flex-direction: column-reverse;
        > div {
            width: 100%;
            &.filesView {
                padding-left: 0px !important;
                max-height: 250px;
            }
        }
    }
}
