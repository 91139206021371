@import "theme/variables";
.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    .header {
        display: flex;
        margin-bottom: 15px;
    }
    .table {
        flex-grow: 1;
    }
}
