@import "theme/variables";

.fieldRowRoot {
    border-radius: 4px;
    border: 1px solid $greyAccent;
    display: flex;
    flex-grow: 1;
    margin: 5px 0px;
    flex-direction: row;
    width: 100%;
    .valueField {
        align-items: center !important;
        & > div {
            align-items: center;
        }
    }
    .rowContent {
        position: relative;
        padding: 10px;
        display: flex;
        flex-direction: column;
        > * {
            align-items: flex-start;
            .formRow {
                align-items: center;
            }
        }
        flex-grow: 1;
    }
    .rowDel {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        z-index: 1;
    }
}
