@import "theme/variables";
@import "theme/mixins";

.root {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .contentRoot {
        position: relative;
        display: flex;
        width: 100%;
        // min-width: 600px;
        flex-direction: column;
        .filterRow {
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 620px !important;
            margin-bottom: 5px;
            margin-left: 310px;
            width: calc(100% - 310px);
            .currWeek {
                min-width: 150px;
            }
        }
        .content {
            flex-grow: 1;
            @include overflowOverlay();
            height: calc(100vh - 233px);
            display: flex;
            > div {
                &:first-child {
                    // employees section
                    width: 300px;
                    min-width: 300px;
                    max-width: 300px;
                }
                &:last-child {
                    // able section
                    flex-grow: 1;
                    margin-left: 10px;
                }
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .root .contentRoot {
        height: calc(100vh - 230px);
        min-width: 600px;
    }
}
@media screen and (max-width: 1200px) {
    .root .contentRoot .filterRow {
        margin-left: 0px;
        width: 100%;
        // @include overflowXOverlay();
        // overflow-y: visible !important;
        .currWeek {
            display: none; // hide in small screen
        }
    }
}
