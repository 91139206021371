@import "theme/variables";
@import "theme/mixins";

.title {
    display: block;
    font-weight: bold;
    color: $textDark;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.flexCol {
    @include flexColumn();
}
