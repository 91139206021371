@import "theme/variables";

.displayId {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    padding-left: 33px !important;
}
.genderTitle {
    min-width: 150px;
}
.careHours {
    min-width: 180px;
}
