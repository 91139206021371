@import "theme/variables";
@import "theme/mixins";

.root {
    @include verticallyCenter();
    min-width: 480px !important;
}
.weekNavigator {
    width: 110px;
    display: flex;
    align-items: center;
    .prev,
    .next {
        cursor: pointer;
        width: 15px;
        height: 15px;
        object-fit: contain;
    }
    .weekLabel {
        display: flex;
        flex-grow: 1;
        margin: 0px 5px;
        min-width: 70px !important;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 5px;
            width: 15px;
            height: 15px;
            object-fit: contain;
        }
    }
}
.areaLabel {
    margin-left: 20px;
    color: $textDark;
}
.areasSelect {
    margin-left: 15px;
    width: 250px;
}
@media screen and (max-width: 520px) {
    .areaLabel {
        display: none; // hide in small screen
    }
}
