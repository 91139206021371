@import "theme/variables";
@import "theme/mixins";

.root{
    width:100%;
    @include flexColumn();
    padding: 2px;

    
}
.title{
    font-weight: 600;
    font-size: 18px;
}

.header{
    display: flex;
    .field{
        flex-grow:1

    }
    .btn{
        max-width: 180px;
        height: 44px;
        margin-left: 12px
    }
}

.list{
    width: 100%;
    max-height: calc(100vh - 275px);
    overflow: auto;
    @include flexColumn();
    .item{
        width: 100%;
        position: relative;
        margin-bottom: 4px;
        @include flexColumn();
        .header{
            font-size: 0.8rem;
            display:flex;
            justify-content: space-between;
            &:first-child{
                margin-left: 15px;
                flex-grow: 1;
            }
            &:last-child{
                flex-grow: 1;
                font-size: 0.7rem;
                color:$textGrey;
            }
        }
        .data{
            width: 100%;
            background-color: $greyLightest2;
            border-radius: 8px;
            padding: 4px;
            display:flex;
            font-size: 0.9rem;
            justify-content: space-between;
            align-items: start;
            &:first-child{
                flex-grow: 1;
            }
            .icons{
                gap: 8px;
                display: flex;
            }
        }
        
    }
}
.dotWrapper {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0px;
    top: 3px;
    > span {
        display: block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: $primary;
    }
}