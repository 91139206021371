@import "theme/mixins";
.createBtn,
.optionBtn {
    height: 38px;
}

.createBtn {
    padding: 8px 16px;
    padding-right: 8px !important;
    border-radius: 4px 0 0 4px;
}

.optionBtn {
    @include flexCenter();
    padding: 8px 16px;
    padding-left: 8px !important;
    border-radius: 0 4px 4px 0;
}
