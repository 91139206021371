@import "theme/variables";

.border {
    border: 1px solid $borderGrey;
    border-radius: 4px;
    padding: 4px;
}
.daysCheckBoxDiv {
    height: 100%;
    display: flex;
    // height: 60px;
    flex-direction: column;
    justify-content: center;
}

.daysCheckBox {
    font-weight: bold;
    margin-right: 10px;
    display: flex;
    align-items: center;
}
