@import "theme/variables";
.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
        background-color: $lightBlue !important;
    }
    .container {
        cursor: pointer;
        padding: 15px 24px 0px 24px;
        width: 100%;
        overflow: hidden;
    }
    .explanation {
        font-style: italic;
        color: $textGrey;
        margin-top: 5px;
        font-size: 0.9;
        cursor: pointer;
    }
}

.thumbnailContainer {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    display: flex;
    position: relative;
    min-width: 100%;
    height: 170px !important;

    img {
        background: black;
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
    }

    &:hover {
        > div {
            height: 100% !important;
            visibility: visible !important;
        }
    }
    > div {
        transition: height 0.5s;
        visibility: hidden;
        height: 0px !important;
    }
}
