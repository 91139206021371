@import "theme/variables";

.dialogRoot {
    padding-top: 30px !important;
    .modal-dialog .modal-content {
        border-radius: 0px !important;
    }
    .closeIcon {
        position: absolute;
        top: -25px;
        right: -25px;
        width: 18px;
        height: 18px;
        object-fit: contain;
        cursor: pointer;
    }
}
.bodyRoot {
    max-width: 100% !important;
}
.title {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 20px !important;
    font-weight: bold;
}
.underline {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: $greyLightest;
}

@media screen and (max-width: 992px) {
    .dialogRoot > [class*="modal-lg"] {
        max-width: 90vw !important;
    }
    .dialogRoot > [class*="modal-xl"] {
        max-width: 95vw !important;
        margin-right: 25px; // for dialog close (cross icon)
    }
}
