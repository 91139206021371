.toggleButton {
    margin-left: 12px;
    font-size: 0.6rem;
    font-weight: bold;
    margin-bottom: 2px;
}
.avatarImg {
    width: 42px;
    height: 42px;
    cursor: pointer;
    object-fit: cover;
}
.userName {
    opacity: 0.7;
    font-weight: bold;
}
.subHeading {
    font-size: 0.8rem;
    opacity: 0.5;

    max-width: 300px;
}
