.iframeRoot {
    height: calc(100vh - 320px);
    margin: -15px -20px -25px -20px;
    > :first-child {
        height: 100%;
    }
}
.documentBtn {
    width: 30px;
    height: auto;
    object-fit: contain;
}
