.headingDesc {
    font-size: 1rem;
    font-family: "Lato";
    color: black;
    margin-top: 10px;
    margin-bottom: 40px;
}

.forgetInput {
    margin-bottom: 30px;
    font-family: "Lato";
}

.forgotButton {
    min-width: 200px;
    height: 59px;
    width: 244px;
    text-transform: uppercase;
}
