@import "theme/variables";
@import "theme/mixins";

.tableOuter {
    max-height: calc(100vh - 200px) !important;
    @include overflowOverlay();
    thead tr th {
        color: $textGrey !important;
        font-weight: 300;
        text-align: start;
    }
    tr {
        height: 50px;
        td {
            text-align: start;
        }
    }

    .icon {
        img {
            height: 32px !important;
            width: 32px !important;
            object-fit: contain;
        }
    }

    .iconName {
        max-width: 460px !important;
    }
}
