@import "theme/variables";

.root {
    .tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
        .content {
            position: relative;
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 250px);
            min-height: calc(100vh - 250px);
        }
    }
}

@media only screen and (max-width: 900px) {
    .tabs .content {
        max-height: calc(100vh - 280px) !important;
        min-height: calc(100vh - 280px) !important;
    }
}
