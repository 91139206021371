@import "theme/variables";
@import "theme/mixins";

.outer {
    color: $textDark;
    font-size: 16px;
    margin-top: 20px;

    > * {
        display: flex;
        align-items: center;
    }
}
.popover {
    display: inline-block;
}
.popoverTr {
    color: $textDark !important;
    font-size: 16px;
    // background-color: red !important;
    img {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        margin-top: -3px;
        object-fit: contain;
    }

    .textEllipse {
        max-width: 420px;
        vertical-align: middle;
        @include ellipseText();
        cursor: pointer;
    }
}

.badge {
    background-color: white;
    cursor: pointer;
    width: 200px;
    display: flex !important;
    align-items: center;
    &.badgeMargin > .badgeTextEllipse {
        width: 180px;
    }

    .badgeTextEllipse {
        text-align: center;
        width: 150px;
        vertical-align: middle;
        @include ellipseText();

        float: left;
    }

    border-radius: 50px;
    padding: 2px 10px;
    display: inline-block;
    margin-left: 10px;

    img {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        margin-top: -3px;
        object-fit: contain;
    }

    &.more {
        color: $textGrey;
        cursor: pointer;
        background-color: transparent;
        min-width: 90px;

        & > span {
            margin-left: 10px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .outer {
        overflow-x: auto;
        overflow-y: hidden;
    }
}
