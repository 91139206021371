@import "theme/variables";

.sectionContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .numberCount {
        line-height: 1;
        font-weight: bold;
        font-size: 2.6rem;
        min-width: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .infoContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .title {
            font-weight: bold;
            font-size: 1.05rem;
        }
        .subtitle {
            font-size: 1.05rem;
            color: $greyLight;
            margin-bottom: 5px;
        }
    }
}

.icon {
    margin-top: 5px;
    margin-bottom: auto;
    margin-left: 10px;
    display: flex;
    cursor: pointer;
    img {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
}
@media screen and (max-width: 1500px) {
    .sectionContainer {
        .numberCount {
            font-size: 2.05rem;
        }
    }
}
