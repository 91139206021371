@import "theme/variables";

.disabled {
    cursor: not-allowed;
    color: $greyAccent !important;
}
.rowRoot {
    width: 100%;
    max-width: 950px;
    margin: 5px 0px;
    align-items: center;
    justify-content: center;
}
.inlineTimeField {
    display: flex !important;
    align-items: center;
    margin-bottom: 0px;
    label {
        width: 60px;
        margin-left: 15px;
    }
}
