@import "theme/variables";
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    button {
        font-size: 0.9rem !important;
    }
    .saveBtn,
    .deleteBtn {
        border-radius: 6px;
        padding: 10px 30px;
        text-align: center;
        text-transform: none;
    }
    .cancel {
        background-color: transparent !important;
        margin-right: 25px;
        border-width: 0px !important;
        color: $greyChateau !important;
    }
}
