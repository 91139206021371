@import "theme/variables";

.list {
    min-width: 300px;
    padding: 4px;
    background-color: $greyLighter;
    margin: 10px;
    height: auto;
    border-radius: 5px;
    overflow-y: auto;
    &.active {
        background-color: $lightGreen;
    }
}

.card {
    background-color: white;
    padding: 5px;
    user-select: none !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 4px 2px;
    border: 1px solid $greyLight;
    font-size: 1rem;
    border-radius: 5px;
    &:focus {
        cursor: not-allowed;
    }
    &.active {
        background-color: $yellowLight;
    }

    // to fix cursor gap issue inside modal (if modal is scroll)
    top: auto !important;
    left: auto !important;
}
.listName {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: black;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    margin: 10px 5px;
}
