@import "theme/variables";

.root {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100vw !important;
    max-width: 100vw !important;
    z-index: $errorLayoutZIndex;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .contentContainer {
        width: 100%;
        min-height: 100vh;
    }
}
.errorBackgroundImage {
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: white;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    img {
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.errorMain {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 2;
    > * {
        width: 100%;
    }
    .errorHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15vw;
        > * {
            padding: 10px 15px;
        }
        .errorLogo {
            img {
                max-width: 100%;
                object-fit: contain;
            }
        }
        .headerButton {
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            .errorButton {
                width: 100%;
                padding: 12px;
                font-size: 1.15rem;
                svg,
                i {
                    font-size: 1rem;
                }
                img,
                svg,
                i {
                    margin-right: 10px;
                }
            }
        }
    }

    .errorMsg {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: 80vh;
        justify-content: space-around;
        color: $textDark !important;
        .errorImage {
            margin: 10px;
            max-height: 45vh;
            img {
                margin-left: -11.5vw;
                max-width: 60vw;
                max-height: 100%;
                object-fit: contain;
            }
        }
        & > div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        h1 {
            font-family: "Kometa";
            font-weight: bold;
            font-style: normal;
            justify-content: space-between;
            margin: 0px;
            font-size: 4.25rem;
            margin-bottom: 5vh;
        }
        p {
            font-size: 2.15rem;
            font-style: normal;
            font-family: "Kometa";
            font-weight: 300;
            max-width: 65vw;
        }
    }
}

.circleIcon {
    margin: 1vh 0px 2vh 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        display: block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        margin: 0.4rem;
        &.pinkIcon {
            background-color: $darkPink;
        }
        &.blueIcon {
            background-color: $primary;
        }
    }
}

@media screen and (min-width: 2500px) {
    .errorMain {
        .errorHeader {
            .errorLogo {
                img {
                    min-height: 90px;
                    max-width: 300px;
                }
            }
            .headerButton {
                width: 300px;
                .errorButton {
                    padding: 16px 25px;
                    font-size: 2rem;
                    letter-spacing: 2.5px;
                    i,
                    svg,
                    img {
                        max-width: 25px;
                        width: 25px;
                        height: 25px;
                        margin-right: 30px;
                    }
                }
            }
        }
        .errorMsg {
            img {
                margin-left: -12.5vw;
            }
            .circle-icon > span {
                width: 1rem;
                height: 1rem;
            }
            h1 {
                font-size: 5rem;
            }
            p {
                font-size: 3rem;
            }
        }
    }
}
@media screen and (max-width: 1750px) {
    .errorMain {
        .errorMsg {
            .errorImage {
                img {
                    margin-left: -11vw;
                    max-width: 55vw;
                }
            }
            h1 {
                font-size: 3.5rem;
            }
            p {
                font-size: 1.8rem;
            }
        }
    }
}
@media screen and (max-width: 1399px) {
    .errorMain {
        .errorMsg {
            .errorImage {
                img {
                    margin-left: -12.5vw;
                    max-width: 60vw !important;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .errorHeader {
        padding: 20px !important;
    }
    .errorButton {
        padding: 15px 10px;
        font-size: 1rem;
    }
    .errorMain {
        overflow-y: auto;
        .errorMsg {
            max-height: 75vh !important;
            .errorImage {
                max-height: 60vh !important;
                img {
                    margin-left: -26vw;
                    max-width: 115vw !important;
                }
            }
            p {
                padding: 15px;
                max-width: 100vw;
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .errorMain {
        .errorMsg {
            .errorImage {
                max-height: 70vh !important;
            }
            h1 {
                font-size: 3rem;
            }
            p {
                font-size: 1.5rem;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .errorMain {
        .errorMsg {
            max-height: 200vh !important;
            .errorImage .img {
                margin-left: -35vw;
            }
        }
    }
}
