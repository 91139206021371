@import "theme/variables";

.infiniteScroll {
    overflow: hidden !important;
}

.tableOuter {
    overflow: overlay;
    @-moz-document url-prefix() {
        // for firefox hide scrollbar
        overflow: auto !important;
    }
    thead tr th {
        color: $textGrey !important;
        font-weight: 300;
        text-align: start;
    }

    tr {
        &:not([class*="dummy-row"]) {
            height: 48px;
            td {
                height: 48px;
                text-align: start;
                > p {
                    margin-top: 4px;
                }
            }
        }
    }
}
