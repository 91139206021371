@import "theme/variables";

.highlight {
    border-radius: 4px;
}
.bgRed {
    background-color: $lightRed;
    color: black;
}

.bgGreen {
    background-color: $lightGreen;
    color: black;
}
