@import "theme/mixins";

.rootEmployee {
    min-width: 2066px !important;
}
.root {
    min-width: 1430px !important;
    overflow-x: hidden !important;
}

.tableRoot {
    @include overflowOverlay();
    table {
        table-layout: auto !important;
    }
}
