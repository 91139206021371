@import "theme/variables";
.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel {
        background-color: transparent !important;
        margin-right: 25px;
        border-width: 0px !important;
        color: $greyChateau !important;
    }
}
