@import "theme/variables";
.container {
    flex-wrap: wrap;
}
.headerIcon {
    width: 25px;
    height: 25px;
    object-fit: contain;
}
.headingSection {
    display: flex !important;
    align-items: center;
    > * {
        display: flex !important;
        &:last-child {
            margin: 0px !important;
            flex-grow: 1;
        }
    }
}
.tabButton {
    width: 250px;
    min-width: 200px;
    flex-grow: 1;
    max-width: 350px;
}
