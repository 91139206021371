@import "theme/variables";
.root {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);

    .documentBtn {
        width: 30px;
        height: auto;
        object-fit: contain;
    }
}
.iframeRoot {
    border: 1px solid $borderGrey;
    // border-radius: 6px;
}
