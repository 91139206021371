@import "theme/variables";
@media screen and (max-width: 768px) {
    .dndRoot {
        max-height: 90vh;
    }
}

.dndRoot {
    display: flex;
    width: 100%;
    max-height: 70vh;
}
.horizontalListsContainer {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.verticalList {
    margin: 10px;
    height: auto;
}
