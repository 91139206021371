@import "theme/variables";

.colorPrimary {
    color: $primary;
    font-weight: 600;
}

.registerButton {
    min-width: 200px;
    height: 59px;
    width: 244px;
    text-transform: uppercase;
}
