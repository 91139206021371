@import "theme/variables";

.root {
}
.headerRight {
    display: flex;
    align-items: center;
    .typeFilter {
        width: 200px;
        margin-right: 15px;
    }
}
.tableOuter {
    tbody {
        tr {
            &.emptyRow {
                &,
                td {
                    cursor: default !important;
                    background-color: transparent !important;
                }
            }
        }
    }
}
