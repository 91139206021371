@import "theme/variables";

.root {
    .headerContent {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .chartsContainer {
        width: 100%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        .chartSection {
            max-width: 33%;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: 990px) {
    .root .chartsContainer .chartSection {
        max-width: 50%;
    }
}
@media only screen and (max-width: 700px) {
    .root .chartsContainer .chartSection {
        max-width: 100%;
    }
}
