@import "theme/variables";

.root {
    width: 100%;
}
.disabled {
    cursor: not-allowed;
    color: $greyAccent !important;
}
.label {
    font-weight: bold;
}
.fieldRowRoot {
    border-radius: 4px;
    border: 1px solid $greyAccent;
    display: flex;
    flex-grow: 1;
    margin: 5px 0px;
    flex-direction: row;
    width: 100%;

    .rowContent {
        position: relative;
        padding: 10px;
        display: flex;
        flex-direction: column;
        > * {
            align-items: flex-start;
        }
        flex-grow: 1;
    }
}
