@import "theme/variables";

.progressBar {
    width: 100%;
    height: 5px;
    margin: auto 10px;
}
.bgColorRed {
    background-color: $red;
}
