@import "theme/variables";
@import "theme/mixins";

.root {
    height: 60px;

    .content {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        flex-grow: 1;
        height: 100%;
        justify-content: space-between;

        .headerText {
            font-weight: 600;
            color: black;
            @include ellipseText();
            max-width: 90%;
            line-height: 60px;
        }

        &,
        & > * {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
}

.textArea {
    width: 100%;
    height: 100%;
}

.displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalFooterRightButtons {
    display: flex;
    justify-content: flex-end;
    min-width: 130px;
}

.dateField {
    font-weight: 300;
}

.marginTop_33 {
    margin-top: 33px;
}
