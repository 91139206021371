@import "theme/variables";
@import "theme/mixins";

.MessageNav {
    display: flex;
    min-width: 300px;
    margin-top: -4px; //to handle search field outline
    height: 100% !important;
    max-height: calc(100vh - 195px);
    width: 300px;
    ul {
        width: 100%;
        margin-right: 12px;
        @include overflowYOverlay();
        flex-wrap: nowrap !important;
        li.searchField {
            margin: 4px;
            margin-bottom: 25px;
        }
        li.active {
            background-color: white !important;

            span {
                color: $primary !important;
            }
            .badge {
                color: black !important;
                background-color: $orangeLight !important;
                border: 1px solid $orangeLight !important;
            }
        }

        li.navItem {
            background-color: $messageChatMenuBg;
            border-radius: 10px;

            margin-bottom: 5px;
            padding: 10px 20px;
            display: block;
            height: 60px;
            cursor: pointer;

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                height: 100%;
                .navLinkIcon {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 25px;
                    height: 100%;
                    padding: 10px 0;
                    margin-right: 25px;
                    img {
                        opacity: 1 !important;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                span {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1.1rem;
                    flex-grow: 1;
                    font-weight: 600;
                    vertical-align: middle;
                    color: $textDark;
                }
                .badge {
                    min-width: 50px;
                    padding: 0 10px;
                    color: $textGrey;
                    text-align: right;
                    border-radius: 13px;
                    border: 1px solid $greyAccent;
                    text-align: center;
                    line-height: 23px;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .MessageNav {
        width: 100%;
        min-width: 280px;
    }

    .MessageNav ul {
        margin-right: 0 !important;

        li.navItem:last-child {
            margin-bottom: 20px !important;
        }
    }
}
