@import "theme/variables";
.buttonInnerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    & > div:first-child {
        flex-grow: 1;
        color: $textDark !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.25rem !important;
    }

    .innerButton {
        border-radius: 50%;
        background: $Onahau;
        border: 1px solid $Onahau;
        width: 28px;
        height: 28px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
            border-color: $chatBlue !important;

            i {
                color: white;
            }
        }
    }
}

.root {
    text-align: left;
    color: $textDark;
    font-weight: 600;
    background: transparent;
    width: 100%;
    padding: 12px 10px;
    overflow: hidden;
    border-radius: 30px;
    border: 1px solid $greyLightest !important;
    &:hover,
    &:focus,
    &:active,
    &.active {
        border-color: $Onahau !important;
        background: $Onahau !important;
        .innerButton {
            border-color: $chatBlue !important;
            &:hover {
                background: $chatBlue !important;
                &,
                * {
                    color: white;
                }
            }
        }
    }

    .icon {
        font-size: 0.9rem !important;
        color: $chatBlue;
    }
}
.rootActive {
    border-color: $Onahau !important;
    background: $Onahau !important;
    .innerButton {
        border-color: $chatBlue !important;
    }
}
