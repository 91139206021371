@import "theme/variables";

.updateImage {
    background-color: $Onahau;
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: 20px;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}
.noChatUserMsg {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $textGrey;
}
.image {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center;
    &:hover {
        cursor: pointer;
    }
}

.header {
    display: flex;
    margin: 15px 0px;
    padding-bottom: 20px;
    .GroupNameDiv {
        padding-top: 10px;
        padding-left: 15px;
        flex-grow: 1;
        .groupNameField {
            font-size: 0.9rem !important;
            padding-right: 20px !important;
            border-color: 1px solid $greyLightest;
            &::placeholder {
                color: $greyChateau !important;
            }
        }
    }
}
.searchDiv {
    padding-bottom: 20px;
    .searchField {
        background: #f4f6f7 !important;
        box-shadow: none !important;
        &::placeholder {
            color: $greyChateau;
        }
    }
}
.memberListDiv {
    overflow: auto;
    max-height: calc(100vh - 500px);
    min-height: 250px !important;
    .listItem {
        display: flex;
        align-items: center;
        border-width: 0px !important;
        padding: 10px;
        border-radius: 4px;
        margin: 1px;
        .popperItem {
            background: transparent !important;
            border: 0px solid red !important;
            margin-bottom: 0px;
        }
        .memberName {
            font-size: 1.1rem;
            font-weight: 600;
            flex-grow: 1;
            padding-left: 10px;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:focus,
        &:active {
            background: transparent;
        }
        &.active {
            background: $OnahauDark;
        }
        .avatar {
            width: 40px;
            height: 40px;
        }
        .tick {
            width: 16px;
            height: auto;
            object-fit: contain;
            object-position: center;
        }
    }
}
