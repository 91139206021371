@import "theme/variables";

.toggleIcon {
    text-align: right;
    padding-left: 10px;
    width: 30px;
    height: 23px;
    font-size: 1rem;
    margin-top: -3px;
    > div {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        i,
        svg {
            color: $greyLighter;
        }
    }
}
