@import "theme/variables";
.saveButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
.authFields {
    height: 44px;
    padding: 20px !important;
    font-size: 0.9rem;
}
.authFiledIconEnd {
    top: 9px;
}
.avatarRight {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: center;

    .fileField {
        margin-top: 20px !important;
        border: 1px solid $greyLighter;
        padding: 3px;
        width: 100%;
        border-radius: 5px;
    }
}
.updateAvatar {
    width: 100px;
    height: 100px;
    max-height: 120%;
    object-fit: cover !important;
    object-position: center !important;
}

.avatarHelpIcon {
    width: 20px;
    height: 20px;
}

.appInputFormGroup {
    margin-bottom: 1rem;
}
