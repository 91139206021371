@import "theme/variables";

.infiniteScroll {
    overflow: hidden !important;
}
.tableClass {
    min-width: 600px !important;
}

.displayId {
    min-width: 70px;
    width: 70px;
    max-width: 70px;
    padding: 0px !important;
    margin: 0px;
    text-align: center !important;
    padding-left: 20px !important;
}

.displayIdSpan {
    padding: 0px !important;
    margin: 0px;
    text-align: center !important;
}

.adoptDiv {
    max-width: 170px;
    min-width: 170px;
    width: 170px;
    text-align: center !important;
}
.loader {
    width: 18px !important;
    height: 18px !important;
    margin-right: 30px;
}
.cloneImage {
    cursor: pointer;
    width: 18px !important;
    height: 18px !important;
    object-fit: contain;
}
.icon {
    img {
        height: 32px !important;
        width: 32px !important;
        object-fit: contain;
    }
}

.noLocationMessageDiv {
    margin-top: 50px;
    width: 100%;
    padding: 10px 40px;
    text-align: center;
    color: $textGrey;
}
