.backgroundImage {
    pointer-events: none;
    position: absolute;
    width: 50%;
    height: 100vh;
    z-index: 1;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 0 0;
    }
}

.main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background-color: white !important;
    .mainLeft {
        box-sizing: border-box;
        pointer-events: none;
        width: 50%;
        height: 100vh;
        display: block;
        padding-top: 6%;
        padding-bottom: 7%;
        padding-left: 5%;
        z-index: 2;
        .imgBaloon {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .mainRight {
        height: 100%;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }
}

.backgroundRight {
    pointer-events: none;
    position: absolute;
    right: 0px;
    top: calc(45% - 150px);
    img {
        width: 130px;
        height: auto;
        object-fit: contain;
    }
}

@media only screen and (max-width: 650px) {
    .backgroundImage {
        display: none !important;
    }
    .mainLeft {
        display: none !important;
    }
    .mainRight {
        width: 100%;
    }
}

@media only screen and (min-width: 650px) and (max-width: 992px) {
    .backgroundImage {
        width: 30% !important;
    }
    .mainLeft {
        width: 30% !important;
    }
    .mainRight {
        width: 70%;
    }
}

@media only screen and (min-width: 992px) {
    .mainRight {
        width: 50%;
    }
}
