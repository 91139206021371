@import "theme/variables";
@import "theme/mixins";

.root {
    display: flex;
    flex-direction: column;
    @include overflowOverlay();
}
.heading {
    color: $primaryDark;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
}

.fileList {
    overflow-y: auto;
    font-size: 14px;
    .fileItem {
        background-color: $primaryLightest;
        display: flex;
        width: 100%;
        min-width: 310px;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0px;
        padding: 10px 20px;
        border-radius: 12.5px;
        .fileName {
            flex-grow: 1;
            max-width: 65%;
            a {
                @include ellipseText();
                width: 100%;
                font-weight: 600;
                color: black;
                text-decoration: none;
            }
        }

        .fileSize {
            margin: 0px 8px;
            color: $greyLight;
            min-width: 70px;
            width: 70px;
        }
        .delete {
            &.fileDeleteBtn {
                cursor: pointer;
            }
            img {
                width: 12px;
                height: 12px;
                object-fit: contain;
            }
        }
    }
}
