@import "theme/variables";

.root {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: -2px;
}
.playButton {
    margin: 0 auto;
    i,
    svg {
        color: $primary;
        font-size: 0.6rem;
        vertical-align: middle;
    }
}
.Popper {
    font-weight: 300;
}

.staffTable {
    thead {
        tr {
            th {
                padding: 5px !important;
                border-top: 1px solid $greyLightest !important;
                font-weight: 600 !important;
            }
        }
    }
    tbody {
        tr {
            td {
                padding-left: 5px !important;
                padding-right: 5px !important;
                padding-bottom: 0px !important;
                padding-top: 0px !important;
                margin: 0px !important;
                font-weight: 300;
                word-wrap: break-word;
                color: $navFontColor;
                text-align: start;
                border-top: 1px solid $greyLightest !important;
                border-bottom: 1px solid $greyLightest !important;
            }
        }
    }
}

.headerDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .box {
        font-weight: 600;
    }
    .in {
        color: $chatBlue;
    }
    .out {
        color: $lightRed2;
    }
}
