@import "theme/variables";

.table {
    .new-select {
        text-align: start !important;
    }
    cursor: default !important;
    text-align: center;
    td {
        padding-bottom: 10px;
        padding-top: 0px;
        vertical-align: middle;
    }
    tr:first-child {
        color: $textGrey;
        font-size: 0.8rem;
    }
    > tbody > tr,
    > thead > tr,
    td,
    th {
        cursor: default !important;
    }
    .totals {
        font-size: 1.5rem;
        font-weight: 700;
        td:first-child {
            color: $textGrey;
            font-weight: 300;
            font-size: 0.9rem;
            span {
                color: $chatBlue;
                font-weight: 700;
                margin-left: 5px;
            }
        }
        td:nth-child(2) {
            color: $chatBlue;
        }
        td:nth-child(3) {
            color: $lightRed2;
        }
    }
}
