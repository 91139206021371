@import "theme/variables";
.select {
    width: 100%;
    max-width: 450px;
}
.hightLightContainer {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.lineTd {
    width: 1px;
    height: 100%;
    max-width: 1px;
    min-width: 1px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    div {
        width: 100%;
        background-color: $greyLightest;
        padding-bottom: 55px;
    }
}
.table {
    .new-select {
        text-align: start !important;
    }
    text-align: center;
    td {
        padding-left: 2px;
        padding-right: 2px;
    }
    tr:first-child {
        color: $textGrey;
        font-size: 0.9rem;
    }
    // for second tr which contains values
    .totals {
        font-size: 1.5rem;
        font-weight: 700;
        //for total active the first td
        > td:first-child {
            color: $textGrey;
            font-weight: 300;
            font-size: 1rem;
            //for total active clients value
            span {
                color: $chatBlue;
                font-weight: 700;
                margin-left: 5px;
            }
        }
        > td:nth-child(2) {
            color: $green;
        }
        > td:nth-child(3) {
            color: $chatBlue;
        }
        > td:nth-child(4) {
            color: $lightRed2;
        }
    }
}

.employeeInOutDiv {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    .employeeInOut {
        font-size: 0.9rem;
        font-weight: 300;
        color: $textGrey;
        padding-right: 4px;
    }

    .textIn {
        color: $chatBlue;
    }
    .textOut {
        color: $lightRed2;
    }
}

.rightAngleImage {
    height: 13px;
    width: 13px;
    object-fit: contain;
}
