@import "theme/variables";

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 90px);
    > {
        width: 100%;
        max-width: 100%;
    }

    .contentMain {
        display: flex;
        flex-direction: column;

        min-height: calc(100vh - 190px) !important;
        max-height: calc(100vh - 190px) !important;
    }
    .loader {
        position: relative !important;
    }
    table[class*="table"] {
        > thead > tr {
            > th {
                color: $textDark !important;
                font-weight: 600 !important;
            }
        }
    }
}
@media screen and (min-width: 1431px) {
    .root .contentMain {
        height: calc(100vh - 190px) !important;
    }
}
@media screen and (max-width: 1430px) {
    .root .contentMain {
        min-height: calc(100vh - 275px) !important;
        max-height: calc(100vh - 275px) !important;
    }
}
@media screen and (max-width: 900px) {
    .root .contentMain {
        min-height: calc(100vh - 260px) !important;
        max-height: calc(100vh - 260px) !important;
    }
}
