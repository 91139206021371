@import "theme/variables";

.errorMsg {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 80vh;
    justify-content: space-around;
    color: $textDark !important;
    .errorImage {
        margin: 10px;
        max-height: 45vh;
        img {
            margin-left: -11.5vw;
            max-width: 60vw;
            max-height: 100%;
            object-fit: contain;
        }
    }
    & > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    h1 {
        font-family: "Kometa";
        font-weight: bold;
        font-style: normal;
        justify-content: space-between;
        margin: 0px;
        font-size: 4.25rem;
        margin-bottom: 5vh;
    }
    p {
        font-size: 2.15rem;
        font-style: normal;
        font-family: "Kometa";
        font-weight: 300;
        max-width: 65vw;
    }
}

.circleIcon {
    margin: 1vh 0px 2vh 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        display: block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        margin: 0.4rem;
        &.pinkIcon {
            background-color: $darkPink;
        }
        &.blueIcon {
            background-color: $primary;
        }
    }
}

@media screen and (min-width: 2500px) {
    .errorMsg {
        img {
            margin-left: -12.5vw;
        }
        .circle-icon > span {
            width: 1rem;
            height: 1rem;
        }
        h1 {
            font-size: 5rem;
        }
        p {
            font-size: 3rem;
        }
    }
}
@media screen and (max-width: 1750px) {
    .errorMsg {
        .errorImage {
            img {
                margin-left: -11vw;
                max-width: 55vw;
            }
        }
        h1 {
            font-size: 3.5rem;
        }
        p {
            font-size: 1.8rem;
        }
    }
}
@media screen and (max-width: 1399px) {
    .errorMsg {
        .error-image {
            img {
                margin-left: -12.5vw;
                max-width: 60vw !important;
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .errorMsg {
        max-height: 75vh !important;
        .errorImage {
            max-height: 60vh !important;
            img {
                margin-left: -26vw;
                max-width: 115vw !important;
            }
        }
        p {
            padding: 15px;
            max-width: 100vw;
        }
    }
}
@media screen and (max-width: 700px) {
    .errorMsg {
        .error-image {
            max-height: 70vh !important;
        }
        h1 {
            font-size: 3rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
}
@media screen and (max-width: 400px) {
    .errorMsg {
        max-height: 200vh !important;
        .error-image .img {
            margin-left: -35vw;
        }
    }
}
