@import "theme/variables";
.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-top: 15px;
    padding-bottom: 20px;
    button {
        font-size: 0.9rem !important;
    }
    .saveBtn {
        border-radius: 6px;
        padding: 10px 30px;
        text-align: center;
        text-transform: none;
    }
    .cancel {
        background-color: transparent !important;
        margin-right: 25px;
        border-width: 0px !important;
        color: $greyChateau !important;
    }
}
.label {
    color: $textDark;
}
.textArea {
    height: 100px !important;
    resize: none;
    font-size: 0.9rem;
}

.editImage {
    margin-top: 36px;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.selectDiv {
    width: calc(100% - 40px);
}
