@import "theme/variables";

.avatarRight {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: center;
    .fileField {
        margin-top: 20px !important;
        border: 1px solid $greyLighter !important;
        padding: 3px;
        width: 100%;
        border-radius: 5px;
    }
}
.updateAvatar {
    width: 100px;
    height: 100px;
    max-height: 120%;
    object-fit: cover !important;
    object-position: center !important;
}
