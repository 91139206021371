@import "theme/mixins";

.fullHeight {
    height: 100% !important;
}

.overlay {
    position: absolute;
    height: 100%;
    @include flexCenter();
    width: 100%;
    z-index: 100;
    overflow: hidden;
    cursor: not-allowed !important;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    .content {
        border-radius: 8px;
        @include flexColumn();
        align-items: center;
        max-width: 350px;
        @include box-shadow(0px, 0px, 15px, rgba(0, 0, 0, 0.25));
        background-color: white;
        padding: 15px;
        cursor: default !important;
        font-size: 1rem;
        font-weight: 500;
        p {
            text-align: center;
            margin-bottom: 10px;
        }
        button {
            text-transform: uppercase;
        }
    }
}
.blur {
    @include blur(10px);
    background: rgba(0, 0, 0, 0.05);
}
