@import "theme/variables";

.appSliderTooltip {
    & div[role="tooltip"] {
        font-size: 13px;
        line-height: 1;
        color: black;
        text-align: center;
        text-decoration: none;
    }
}

.appSliderSpan {
    color: $textGrey;
}
