@import "theme/variables";

.select {
    min-width: 150px;
}
.valueCell {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    .inputField {
        width: 80px;
        max-width: 80px;
    }
    > span {
        min-width: 90px !important;
        display: table-cell;
    }
}
.copyIcons {
    display: flex;
    width: 70px;
    min-width: 70px;
    justify-content: space-around;
    align-items: center;
    img {
        cursor: pointer;
        width: 22px !important;
        height: 22px !important;
        object-fit: contain;
        color: $primary;
    }
}
