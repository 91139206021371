@import "theme/variables";

.active {
    background-color: $primary !important;
}
.filterButton {
    color: $textDark;
    border: 0px solid $bgMain;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: $bgMain;
    &:hover {
        background-color: $primaryDark !important;
    }
}
.conditionalFields {
    margin: 0px;
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
    height: 100px;
    > div {
        width: 100%;
    }
    .hasNoLimitDiv {
        margin-top: 20px !important;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        .noLimit {
            margin-top: 0px;
            display: flex;
            align-items: center;
            height: 38px;
            width: 240px;
        }
    }
}
.label {
    font-weight: 300 !important;
}

.recurrenceSwitch {
    margin-top: 20px;
}
.numberField {
    margin: 0 !important;
}

.repetaJob {
    width: 230px;
    margin-top: 10px;
}
.hasNoEndDate {
    height: 60px;
    padding-top: 17px;
}
