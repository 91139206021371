.root {
    display: flex;
    align-items: center;
    .avatarRoot {
        width: 40px;
        height: 40px;
        max-width: 100px;
        margin-left: -10px;
    }
}

.avatar {
    * {
        font-size: 1rem !important;
    }
}
