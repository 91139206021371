@import "theme/mixins";

table.childTable > tbody > tr > td:last-child {
    border-right: 15px solid white !important;
}
.textEllipse {
    margin: 0px;
    @include ellipseText();
}
.yearLabel {
    padding: 6px;
    margin: -6px;
    border-radius: 4px;
}
