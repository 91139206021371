@import "theme/variables";

.listItem {
    border: 0px solid red !important;
    margin: 15px 0px;
    margin-top: 28px;
}

.messageTextDiv {
    display: flex;
    flex-direction: column;
    .author {
        font-weight: bold;
        padding: 0px 10px;
        font-size: 0.95rem;
        margin-bottom: -3px;
        color: $textDark;
    }
    .messageTimeInfo {
        display: flex;
        // justify-content: flex-end;
        align-items: center;
        min-width: 120px;
        width: 100%;

        &.ownMessageInfo {
            flex-direction: row-reverse;
            // justify-content: flex-end;
        }

        .timeSpan {
            padding: 0px 10px !important;
            font-size: 0.8rem !important;
            color: $greyChateau !important;
            text-align: right;
        }
        .messageTick {
            width: auto;
            height: 12px;
            object-fit: contain;
            margin: 0 10px;
            margin-top: -4px !important;
        }
    }
}
.menuIcon {
    width: 20px;
    height: auto;
    object-fit: contain;
    margin: 0px;
}
.messageBubble {
    padding: 10px 24px;
    margin-bottom: 4px;
    word-break: break-word;
    text-align: left !important;
}
.ownMessageBubble {
    background-color: $primary;
    color: white;
    border-radius: 10px 0px 10px 10px;
}
.otherMessageBubble {
    background-color: #f4f6f7 !important;
    border-radius: 0px 10px 10px 10px;
    .mediaBorder {
        color: $OnahauDark !important;
    }
}

.messageBodyContainer {
    padding: 5px 10px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    word-break: break-all;
    white-space: normal;
}
.ownMessageBodyAlignment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.otherMessageBodyAlignment {
    display: flex;
    align-items: center;
}

.otherMessageBodyAlignment .mediaItemText span {
    color: $greyChateau;
    &.mediaName {
        color: $textDark;
    }
}

.avatar {
    min-width: 38px;
    width: 38px;
    height: 38px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500 !important;
    font-size: 1rem !important;
    * {
        font-size: 1rem !important;
    }
    &.avatarMarginPrivate {
        margin-top: -2px;
    }
    &.avatarMarginGroup {
        margin-top: -7px;
    }
}
.messageOptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
    color: $greyChateau !important;
}
.mediaItemText {
    display: flex;
    align-items: center;
    & > span:first-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-grow: 1;
        padding: 2px 0;
        padding-right: 20px;
        > * {
            width: 100%;
        }
        span {
            font-size: 0.85rem;
            text-align: left;
            &.mediaName {
                font-size: 0.95rem;
                text-decoration: none;
                cursor: pointer;
                font-weight: 600;
            }
        }
    }
    .mediaBorder {
        height: 40px;
        width: 1px;
        background: $greyLightest;
        margin: 3px 6px;
    }
    .imageDiv {
        padding-right: 10px;
        padding: 0px 10px;
        cursor: pointer;
        display: flex;
        margin-left: 4px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
            width: 18px;
            height: auto;
            object-fit: contain;
            object-position: center;
        }
    }
}
