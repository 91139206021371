.numberInput {
    min-height: 29px;
    height: 29px;
    margin: 0;
    .inputField {
        padding: 0px 10px !important;
        min-height: 29px;
        max-height: 29px;
    }
}
