@import "theme/variables";
.root {
    display: flex;
    position: relative;
    justify-content: center;
    padding: 0px 10px;
}
.icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    object-fit: contain;
}
.hiddenInput {
    width: 1px;
    opacity: 0;
    z-index: -1;
    position: absolute;
}
.container {
    background-color: white;
    border-radius: 20px;
    padding: 4px;
    margin: 0px;
    max-width: 625px !important;
    display: flex;
    overflow: auto;
    [class*="react-datepicker"] {
        border-width: 0px !important;
    }
    &.addMarginLeft {
        [class*="react-datepicker__month-container"] {
            &:last-child {
                // no margin on first 3
                margin-left: 25px;
                // margin-left: 0px;
            }
        }
    }
}
.button {
    width: 350px;
    max-width: 350px;
    padding: 10px 20px;
    font-size: 1.05rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: $Onahau;
    color: $veniceBlue;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    border: 0px !important;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none !important;
}
