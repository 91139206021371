@import "variables";

.timepicker {
    display: block !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
    position: absolute !important;
    // height: 172px;
    top: 5px !important;
    inset: unset !important;
    width: unset !important;
    border-radius: 15px !important;
    transform: translateY(44px) !important;
    &::before,
    &::after {
        content: none;
        display: none;
    }
    .btn {
        border-radius: 50%;
    }
    .timepicker {
        table {
            td {
                height: 40px;
                line-height: 40px;
            }
            a,
            td,
            th,
            span,
            i {
                &:hover {
                    border-radius: 50%;
                }
            }
        }
        .timepicker-picker {
            td,
            th {
                padding: 0px !important;
                height: 55px;
                line-height: 55px;
            }
        }
        .timepicker-minutes {
            td {
                height: 40px;
                line-height: 40px;
            }
        }
    }
}

.timepicker-fix {
    position: relative;
    .bootstrap-datetimepicker-widget.dropdown-menu {
        top: 40px !important;
        left: 35px !important;
        position: absolute !important;
    }
}
