.totalsContainer {
    display: flex;
    justify-content: space-around;
    margin: 10px 40px;
    flex-wrap: wrap;
    margin-bottom: -5px !important;
    > span {
        width: calc(50% - 10px) !important;
        margin: 5px;
    }
}
