.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.tabButton {
    width: 250px;
    min-width: 200px;
    flex-grow: 1;
    max-width: 350px;
}
.filterRoot {
    justify-content: flex-end;
    margin-bottom: 0px !important;
    > :first-child {
        margin-right: 15px;
        width: 300px;
    }
}
.createButtonDiv {
    max-width: 320px;
    min-width: 250px;
}

.ownDocumentTabBtn {
    width: 290px !important;
}

.templateLibraryTabBtn {
    width: 290px !important;
}

.activeTabBtn {
    width: 290px !important;
}
@media only screen and (max-width: 800px) {
    .header {
        overflow-x: auto;
    }
    .filterRoot > :first-child {
        margin-right: 0px !important;
    }
}
