@import "theme/variables";
.root {
    display: flex;
    flex-direction: column;
}
.content {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
