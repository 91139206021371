@import "theme/variables";
@import "theme/mixins";
.root {
    display: flex;
    background-color: $greyLightest2;
    width: 120px;
    height: 30px;
    // border: 1px solid $greyLightest;
    border-radius: 4px;
    align-items: center;
    > span {
        font-size: 0.95rem;
        flex-grow: 1;
        text-align: center;
        color: $textDark;
        display: block;
    }
    .button {
        width: 30px;
        height: 100%;
        padding: 4px auto;
        @include flexCenter();
        &:first-child {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
        &:last-child {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
        }
    }
}
