@import "theme/variables";
.root {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
    .header {
        display: flex;
        margin: 15px 0px;
        justify-content: space-between;
    }
}
.yearSelector {
    margin-bottom: 10px;
}
.copyIcon {
    color: $primary;
}
