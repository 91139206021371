@import "theme/variables";

.termsConditions {
    font-size: 0.9rem;
    font-family: "Lato";
    color: black;
    margin-top: 4px;
    width: 122%;
    margin-bottom: 20px;
    padding-right: 20px;
}

.colorPrimary {
    color: $primary;
    font-weight: 600;
    margin: 0px;
}

.registerButton {
    min-width: 200px;
    height: 59px;
    width: 244px;
    text-transform: uppercase;
}

// @media only screen and (max-width: 650px) {
//     .termsConditions {
//         width: calc(100% + 30px);
//     }
// }

// @media only screen and (min-width: 650px) and (max-width: 992px) {
//     .termsConditions {
//         width: calc(70vw - 80px);
//     }
// }

// @media only screen and (min-width: 992px) {
//     .termsConditions {
//         width: calc(50vw - 200px);
//     }
// }
