.tabButton {
    width: 250px;
    min-width: 200px;
    flex-grow: 1;
    max-width: 350px;
}

.createButtonDiv {
    max-width: 320px;
    min-width: 250px;
}

.ownDocumentTabBtn {
    width: 290px !important;
}

.templateLibraryTabBtn {
    width: 290px !important;
}

.activeTabBtn {
    width: 290px !important;
}
