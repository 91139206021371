@import "theme/variables";

.root {
    .headerNavigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80px;
    }
    .tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
        .content {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 245px);
            min-height: calc(100vh - 245px);
        }
    }
}
@media screen and (max-width: 900px) {
    .root .tabs .content {
        max-height: calc(100vh - 285px);
        min-height: calc(100vh - 285px);
        &.withoutNavigation {
            max-height: calc(100vh - 227px) !important;
            min-height: calc(100vh - 227px) !important;
        }
    }
}
