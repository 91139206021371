.icon {
    img {
        height: 25px !important;
        width: 25px !important;
        object-fit: contain;
    }
}
.displayId {
    padding: 0px !important;
    margin: 0px;
    text-align: center !important;
}

.tdImage {
    cursor: pointer;
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.displayField {
    width: 50px;
    min-height: 25px;
    height: 25px;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.9rem;
    text-align: center;
}
.fieldContainer {
    &,
    * {
        margin-bottom: 0px !important;
    }
}
