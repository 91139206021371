@import "theme/variables";
.row {
    display: flex;
    align-items: center;

    .icon {
        width: 40px;
        display: flex;
        height: 40px;
        &:hover {
            cursor: pointer;
        }
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        object-fit: contain;
        object-position: center;
        padding-bottom: 2px;
        margin-left: 10px;
        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }
}
.previewImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 5px;
}
.input {
    padding: 20px !important;
    font-size: 0.9rem;
}
.label {
    color: $textDark;
}
