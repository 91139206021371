.slide-enter {
    height: 0px;
}

.slide-enter.slide-enter-active {
    height: 100%;
    transition: height 0.5s ease;
    -webkit-transition: height 0.3s ease;
}

.slide-exit.slide-exit-active {
    height: 0px;
    transition: height 3s ease;
    -webkit-transition: height 3s ease;
}
