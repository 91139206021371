@import "theme/variables";

.itemOuter {
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
    > * {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.textDiv {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    flex-grow: 1;
    padding-left: 10px;
    overflow: none;
    margin-right: 10px;
    > * {
        width: 100%;
    }
}

.rightPortion {
    display: flex;
    justify-content: space-between;
    > div:first-child {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        // flex-grow: 1;
        // width: 200px;
        padding-left: 10px;
        overflow: none;
        margin-right: 10px;
        > * {
            width: 100%;
        }
        .newText {
            display: flex;
            flex-grow: 1;
            justify-content: center;
        }
    }
}
