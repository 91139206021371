.fieldsContainerRoot {
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    .embedDiv {
        min-height: calc(max(70vh, 400px));
        position: relative;
        min-width: 301px;
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        // > * {
        //     width: 100%;
        // }
    }
}
