@import "theme/mixins";
// .root {
//     min-width: 905px !important;
// }
.tableRoot {
    @include overflowOverlay();
    table {
        table-layout: auto !important;
    }
}
