@import "theme/variables";

.manualEntryDiv {
    display: flex;
    align-items: center;
    .manualEntryBtn {
        width: 20px;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: white !important;
        i,
        svg {
            font-size: 0.85rem;
        }
    }
}

.commentInput,
.manualEntryField {
    min-height: 29px;
    height: 29px;
    margin: 0;
    .inputField {
        padding: 0px 10px !important;
        min-height: 29px;
        max-height: 29px;
    }
    &.manualEntryField {
        margin: 0px 10px !important;
        .inputField {
            text-align: center;
        }
    }
}
.btnBlue {
    background-color: $primary;
    &:hover,
    &:focus,
    &:active {
        background-color: $primary !important;
    }
    &:disabled {
        background-color: $blueDimmed;
    }
}

.btnRed {
    background-color: $red;
    border: 0px solid;
    &:hover,
    &:focus,
    &:active {
        background-color: $red !important;
    }
    &:disabled {
        background-color: $lightRed2;
    }
}
