@import "theme/variables";

.rememberForget {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 22px;
    font-family: "Lato";
    > * {
        justify-content: center;
        align-items: center;
    }
    div {
        width: 50%;
        height: auto;
        &.checkBox {
            align-content: flex-start;
            justify-content: center;
            display: flex;
            > div {
                width: 100%;
                label {
                    color: black;
                    font-size: 0.9rem;
                    padding-left: 2px;
                    padding-top: 2px;
                }
            }
        }
        &.forgotLink {
            text-align: end;
            vertical-align: middle;
            font-size: 0.9rem;
            padding-top: 2px;
            a {
                color: #16aaff;
            }
        }
    }
}

.loginButton {
    min-width: 200px;
    height: 59px;
    width: 244px;
    text-transform: uppercase;
}
