@import "theme/variables";
@import "theme/mixins";

.headerRoot {
    padding-left: 0px !important;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: $messageHeaderHeight;
    align-items: center;

    .headerText {
        margin-left: 15px;
        margin-right: 15px;
        width: auto;
        max-width: 350px;
        font-weight: 600;
        vertical-align: middle;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.15rem;
        color: $textDark;
        @include ellipseText();
    }
}

.createBody {
    padding: 10px;
    min-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;

    & > * {
        display: flex;
        flex-direction: row;
        // align-items: center;
        color: $textGrey;
    }

    .marginBottom {
        margin-bottom: 20px;
    }

    .receiptsSelectors {
        margin: 0px !important;
        padding: 0px !important;
        > * {
            &:first-child {
                padding-left: 0px !important;
            }
            &:last-child {
                padding-right: 0px !important;
            }
        }

        label {
            color: $textDark !important;
        }

        > div {
            margin-bottom: 0 !important;
        }
    }

    .columnLabel {
        color: $textGrey;
        min-width: 66px;
        width: 66px;
    }
    .fromName {
        color: $textDark;
        font-size: 0.9rem;
        font-weight: 600;
        width: auto;
        padding: 12px 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $greyLightest2;
        border-radius: 10px;
    }

    .allowAnswerSwitch {
        margin-left: auto;
        label {
            color: $textDark;
            font-weight: 600;
        }
    }

    .titleField {
        display: flex;
        flex-direction: column;
        width: calc(66.66% - 68px);

        .titleInput:focus {
            color: $inputColor !important;
        }
    }

    .textArea {
        width: 100%;
        height: 230px;
        overflow: hidden;
    }
    .attachmentsLabel {
        margin-left: 66px;
        font-size: 0.8rem;
        color: $textGrey;
    }
    .attachmentSave {
        margin-left: 66px;
        margin-top: 40px;
        align-items: center;

        img {
            cursor: pointer;
            width: 25px;
            height: 25px;
            margin-right: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: contain;
        }
        .sendButton {
            display: flex;
            margin-left: auto;
            font-size: 0.9rem;
            button {
                display: block;
                padding: 10px;
                width: 120px;
                height: 45px;
                font-size: 0.95rem;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    margin-left: 10px;
                    img {
                        width: 20px;
                        object-fit: contain;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .titleField {
        width: 100% !important;
    }
}
