@import "theme/variables";
@import "theme/mixins";
@import "theme/mixins";

.root {
    position: relative;
    @include overflowYOverlay();

    &.overflowHidden {
        overflow: hidden;
    }
    width: 100%;
    border: 1px solid $greyLightest;
    background-color: white;
    > div {
        width: 100%;
    }

    .body {
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 19px;
        padding-left: 19px;
    }
    .footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    .innerScroll {
        height: calc(100% - 70px);
        max-height: 100%;
        flex-grow: 1;
        padding-bottom: 10px;
        @include overflowOverlay();
    }
}
