@import "theme/variables";
.root {
    display: flex;
    width: 100%;
    position: relative;
    height: calc(100vh - 190px);
    > * {
        height: 100% !important;
    }
    .left {
        display: flex;
        min-width: 440px;
        width: 440px;
        margin-top: -5px;
        max-width: 440px;
        flex-direction: column;
    }
    .right {
        min-width: 500px;
        display: flex;
        width: 100%;
        > * {
            width: 100%;
        }
    }
}
.appTab {
    background-color: white !important;
    border-radius: 30px;
}

.tabButton {
    width: 250px;
    min-width: 250px;
    flex-grow: 1;
    max-width: 250px;
}

.searchModule {
    padding: 5px 12px;
    padding-left: 4px !important;
}
.showLatest {
    display: flex;
    padding: 6px;
    padding-left: 12px;
    color: $greyChateau !important;
    & > * {
        cursor: pointer;
    }
    & > svg {
        width: 20px !important;
        height: 20px;
        object-fit: contain;
        padding-left: 10px;
    }
}

.channelBoxDiv {
    overflow-y: auto;
    flex-grow: 1;
    overflow-x: hidden;
    margin: 0 12px;
    margin-left: 0px;
}

@media screen and (max-width: 900px) {
    .root {
        height: calc(100vh - 230px);
        overflow-x: auto;
    }
}
@media screen and (max-width: 1500px) {
    .root .left {
        width: 350px !important;
        min-width: 350px;
    }
}

@media screen and (min-width: 2000px) {
    .root .left {
        width: 100% !important;
        max-width: 600px;
    }
}
