@import "theme/variables";
@import "theme/mixins";
.root {
    display: flex;
    // border-radius: 30px;
    // background-color: white;
    flex-grow: 1;
    justify-content: center;
}
.container {
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    background-color: white;
    display: flex;
    > * {
        flex-grow: 1;
    }
}
.allowOverflow {
    overflow: initial !important;
    margin: 0px 15px;
}
.rightContent {
    border-left: 1px solid white;
    @include flexCenter();
    width: 50px;
    margin-left: 20px;
    padding: 2px 15px;
    height: 100%;
    .content {
        @include flexCenter();
        &.disabled {
            &,
            * {
                cursor: not-allowed !important;
            }
        }
        &.hover {
            background-color: $lightBlue;
        }
        height: 100%;
        width: 100%;
        min-width: 40px;
        border-radius: 50%;
        img,
        svg,
        i {
            cursor: pointer;
        }
    }
}
.rightContentIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
}
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .container {
            overflow: visible; // fix for safari
        }
    }
}
.tabButton {
    padding: 5px 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 50px;
    color: $textDark !important;
    box-shadow: none !important;
    font-size: 1.05rem;
    border-radius: 30px;
    background-color: transparent !important;
    border-width: 0px;
    max-width: fit-content;
    &:hover,
    &:active {
        color: $textDark !important;
    }
    &.disabled,
    &:disabled {
        cursor: not-allowed !important;
        user-select: none !important;
        pointer-events: none !important;
    }
    &.withContent {
        padding-right: 10px !important;
    }
    .text {
        flex-grow: 1;
        white-space: pre;
    }
    &:not(.active) {
        .rightContent {
            visibility: hidden !important;
        }
    }
}
.active {
    color: white !important;
    &:hover,
    &:active,
    &:focus {
        color: white !important;
    }
}

.animated {
    .active {
        &:is(:hover):is(:focus) {
            color: $textDark !important;
        }
    }
    .tabButton {
        padding: 5px 50px;
        margin: 0px -15px;
        z-index: 1;
        transition: color 0.3s, z-index 1s;
        &:active,
        &:focus,
        &.active,
        &[class*="active"] {
            &:not(.activeSelector) {
                background-color: transparent !important;
                border-color: transparent !important;
                outline: none !important;
                box-shadow: none !important;
            }
            z-index: 2 !important;
        }
        &:hover {
            transition: z-index 0s !important;
            &:not([class*="active"]) {
                z-index: 0 !important;
            }
        }
        &:not([class*="active"]) {
            &:active,
            &:focus {
                color: $textDark !important;
            }
        }
    }
}
.hoverColor {
    color: $textDark !important;
    &:hover:not(:focus):not(:active):not(.active) {
        background-color: $primaryLightest !important;
    }
}
.activeSelector {
    height: 100%;
    position: absolute;
    z-index: 1 !important;
    transition: left 0.35s ease-in !important;
    background-color: $primary !important;
    border-width: 0px !important;
    margin: 0px !important;
    &:hover {
        border-radius: 30px !important;
        background-color: $primary !important;
        color: $greyLightest !important;
    }
    & + .tabButton:hover {
        background-color: transparent !important;
    }
}
