@import "theme/variables";
@import "theme/mixins";

.tableBody {
    font-size: 0.9rem;
    font-family: Arial, Helvetica, sans-serif;
}
.fixedHeight {
    tr {
        height: 48px !important;
    }
}
.textEllipse {
    width: 150px;
    vertical-align: middle;
    @include ellipseText();
}

.iconName {
    max-width: calc(min(50vw, 750px)) !important;
}

@media screen and (max-width: 2000px) {
    .iconName {
        max-width: 260px !important;
    }
}
@media screen and (min-width: 2800px) {
    .iconName {
        max-width: calc(min(65vw, 1500px)) !important;
    }
}

.bgRed {
    background-color: $lightRed;
    border-radius: 4px;
    color: black;
}
