@import "theme/variables";
@import "theme/mixins";

a.outerDiv {
    width: 300px;
    text-decoration: none;
    list-style-type: none;
}

.firstRow {
    &,
    > * {
        display: flex;
        align-items: center;
    }
    .notificationIcon {
        width: 19px;
        height: 19px;
        object-fit: contain;
    }

    .titleDiv {
        // min-width: 270px;
        max-width: calc(100% - 30px);
        margin-left: 10px;
        .cursorPointer {
            cursor: pointer;
        }
        .title {
            width: 100%;
            max-width: 99%;
            text-align: start;
            margin: 0;
            padding: 0;
            margin: 0px;
            font-size: 14px;
            color: black;
            @include ellipseText();
        }
    }
}

.secondRow {
    margin-top: 4px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .timeStamp {
        margin-left: 28px;
        color: $textGrey;
        font-size: 12px;
    }
    .dotWrapper {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: block;
        > span {
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background-color: $primary;
        }
    }
}

.hrStyling {
    color: $textGrey;
    margin: 0;
    margin-bottom: 12px;
}

@media screen and (min-width: 2000px) {
    .titleDiv {
        min-width: 420px !important;
    }
    a.outerDiv {
        width: 450px !important;
    }
}
