@import "theme/variables";

.tableBody {
    min-width: 1052px;
    &.tableBodyClient {
        min-width: 1252px;
    }
    tbody {
        font-size: 0.9rem;
        font-family: Arial, Helvetica, sans-serif;
        > tr {
            cursor: pointer !important;
            > td,
            > th {
                cursor: pointer !important;
            }
        }
    }
}
.tbWidth {
    max-width: 200px;
    min-width: 200px;
    width: 200px;
}
