@import "theme/variables";

.toggleIcon {
    text-align: right;
    padding-left: 10px;
    width: 30px;
    height: 22px;
    margin-top: -3px;
    font-size: 1rem;
    > div {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            color: $greyLighter;
        }
    }
}

table.childTable > tbody > tr > td:last-child {
    border-right: 18px solid white !important;
}
