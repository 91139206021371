@import "theme/variables";
.root {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
    .header {
        display: flex;
        margin: 15px 0px;
        justify-content: space-between;
    }
    .table {
        flex-grow: 1;
    }
}
.copyIcon {
    color: $primary;
}
.valueCell {
    align-items: center;
    justify-content: end;
    display: flex;
    .inputField {
        margin: 0px !important;
    }
}
