@import "theme/variables";
@import "theme/mixins";

.card {
    @include flexColumn();
    min-width: 350px;
    width: 350px;
    min-height: 350px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: white;
    border: 1px solid $borderGrey;
    padding: 20px;

    &.cardError {
        border: 1px solid $red;
    }

    > div {
        @include flexColumn();
        align-items: flex-start;
        > * {
            display: flex;
            width: 100%;
            align-items: center;
        }
    }
}
.header {
    position: relative;
    .badge {
        color: black;
        position: absolute;
        right: -30px;
        top: -5px;
        font-size: 0.9rem;
        font-weight: 600;
        height: 30px;
        width: 110px;
        border-radius: 4px;
        background-color: $yellowDark;
        &.active {
            background-color: $green !important;
        }
        .badgeImage {
            width: 16px;
            height: 16px;
            object-fit: contain;
        }
    }

    .deleteBtn {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
    }

    .basicPlanWithTag {
        justify-content: space-between;
        align-items: flex-start;

        .basicPlan {
            color: black;
            font-size: 30px;
            font-weight: 600;
            display: flex;
            max-width: 275px;

            .basicPlanTitle {
                width: 100%;
                max-width: 260px;
                @include ellipseText();
            }
        }

        .tagImage {
            width: 42px;
            height: 42px;
            object-fit: contain;
        }
    }

    .perMonthCostDiv {
        font-size: 30px;
        font-weight: 600;
        color: $primary;
        align-items: baseline;

        .monthText {
            font-weight: 300;
            font-size: 16px;
            color: $textDark;
            letter-spacing: 0.5px;
        }
    }
}

.body {
    flex-grow: 1;
    overflow: hidden;
    justify-content: space-between;
    .content {
        .notExistError {
            color: $red;
        }
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .description {
            width: 100%;
            height: 60px;
            overflow: auto;
            font-size: 13px;
            color: $textGrey;
        }

        .modules {
            width: 100%;
            margin: 10px 0;
            flex-direction: column;
            align-items: flex-start;
            @include overflowYOverlay();
            max-height: 320px;
            .moduleItem {
                padding: 5px 0;
                width: 100%;
                display: flex;
                align-items: center;

                font-size: 16px;
                .check {
                    color: $green;
                    margin-right: 18px;
                }

                .minus {
                    color: $red;
                    margin-right: 18px;
                }

                .moduleItemText {
                    color: black;
                    font-size: 14px;
                }
            }
        }
    }
    .btnContainer {
        justify-content: center;
        padding: 3px;
        .planBtn {
            width: 250px;
            font-weight: 600;
            font-size: 1rem;

            .planBtnDiv {
                font-weight: 600;

                .btnImage {
                    margin-left: 10px;
                    height: 16px;
                    width: 16px;
                    object-fit: contain;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .card {
        width: 100%;
        margin-right: 15px;
    }
}
