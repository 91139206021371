@import "theme/variables";

.root {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $textDark !important;
    .imgRoot {
        display: flex;
        height: 100%;
        font-size: 1.25rem;
        font-weight: 600;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 50% !important;
        background-color: $greyLightest2;
        overflow: hidden;
        text-transform: capitalize;
        img {
            max-width: 100%;
            min-width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    .onlineDot {
        bottom: 2px;
        right: 5px;
    }
}
