@import "theme/variables";

.tableOuter {
    margin-top: 20px;
    // max-height: calc(100vh - 300px) !important;
    overflow: overlay;
    @-moz-document url-prefix() {
        // for firefox hide scrollbar
        overflow: auto !important;
    }
    thead tr th {
        color: $textGrey !important;
        font-weight: 300;
        text-align: start;
    }
    tr {
        height: 50px;
        td {
            text-align: start;
        }
    }
}

.displayTableRow {
    display: table-row-group;
}

.displayNone {
    display: none;
}

.toggleButton {
    display: flex;
    justify-items: center;
    font-size: 14px;
}
