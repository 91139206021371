@import "theme/mixins";
// full calendar fixes
.fc-header-toolbar .fc-toolbar-chunk {
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
        background-color: $primaryDarkest !important;
        border-width: 0px !important;
    }
    button.btn.btn-primary {
        border-width: 0px !important;
        &:hover,
        &:focus,
        &:active {
            background-color: $primaryDarkest !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

// .fc-license-message {
//     display: none !important;
// }
.fc-view-harness {
    background-color: white;
    overflow: hidden;
    &,
    > * {
        border-radius: 8px;
    }
    .fc-scroller-harness .fc-scroller {
        display: flex;
        flex-direction: column;
        > div:first-child {
            flex-grow: 1;
        }
        table.fc-scrollgrid-sync-table {
            border-bottom-style: initial !important;
        }
    }
    > div:not(.fc-resource-timeline) {
        .fc-scrollgrid-sync-table {
            height: 100% !important;
        }
    }
    .fc-scrollgrid-sync-table {
        .fc-scrollgrid-sync-inner {
            &:hover {
                cursor: pointer;
            }
            .fc-event,
            .fc-event-main {
                white-space: pre-wrap;
                word-break: break-all;
            }
            .fc-event,
            .fc-daygrid-event-harness {
                .fc-daygrid-event {
                    &:hover {
                        filter: brightness(0.97);
                    }
                }
            }
        }
    }
}

table.fc-scrollgrid-sync-table tr td {
    background-clip: padding-box;
}

.fc-timeline-event:not(.fc-event-start):before,
.fc-timeline-event:not(.fc-event-end):after {
    content: none !important;
}

.fc-event-start.fc-event-end {
    color: black !important;
}

.fc-event .fc-event-main {
    white-space: break-spaces !important;
}

.fc-toolbar-chunk:last-child .btn-group:first-child {
    .btn-primary:nth-child(n + 2) {
        &:empty {
            display: none;
        }
    }

    .btn-primary:first-child {
        &:not(.fc-dayGridMonth-button) {
            border-radius: 4px !important;
        }
    }
}

.fc-popover {
    opacity: 1;
}

.fc-event,
.fc-event-dot {
    background-color: $yellow;
    border-color: transparent;
}
.fc {
    .fc-timegrid-body,
    .fc-timeline-body {
        min-height: auto !important;
    }
}
.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
    padding-bottom: 0px !important;
}
.fc-datagrid-body tr {
    // height: 60px !important;
    td {
        // height: 60px !important;
        .fc-datagrid-cell-cushion {
            padding: 5px;
        }
        .fc-datagrid-cell-main {
            @include ellipseText();
        }
    }
}
th.fc-datagrid-cell {
    height: 30px !important;
    vertical-align: middle !important;
}

.event-dialog-time-row {
    height: 100px !important;
    margin-top: 15px;

    > * {
        height: 100%;
    }
}
@media screen and (min-width: 900px) {
    .fc-view-harness {
        .fc-scrollgrid-sync-table {
            .fc-scrollgrid-sync-inner {
                .fc-event,
                .fc-event-main {
                    @include ellipseText();
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .fc .fc-toolbar {
        flex-direction: column;
    }
}
