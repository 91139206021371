.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.focus {
    background-color: $primaryDark !important;
    box-shadow: none;
}
.btn-info,
.btn-primary {
    background-color: $primary;
    border-color: $primary !important;

    &.disabled,
    &:disabled {
        background-color: $primary;
        color: white !important;
    }
}

.green-btn,
.btn-success {
    background-color: $darkGreen;
    border-color: transparent !important;
    &:hover,
    &:focus {
        background-color: $green;
    }
    &:disabled {
        background-color: $darkGrey !important;
    }
    color: white;
}
.text-success {
    color: $green !important;
}
.gradient-button {
    background-image: linear-gradient(to right, $darkPink, $primary);
    border-radius: 8px;
    text-align: center;
    color: white;
    padding: 10px 15px;
    font-size: 1rem;
    letter-spacing: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lato";
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    border-width: 0px !important;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.9;
        color: white !important;
    }

    i,
    img {
        margin-right: 10px;
        max-width: 15px;
        height: auto;
        object-fit: contain;
    }
}
