@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

@font-face {
    font-family: "SukhumvitSet";
    src: url("../../assets/fonts/SukhumvitSet-Text.ttf") format("truetype");
}
@font-face {
    font-family: "Terminal";
    src: url("../../assets/fonts/Terminal.ttf") format("truetype");
}
@font-face {
    font-family: "Andale Mono";
    src: url("//db.onlinewebfonts.com/t/663974c9fe3ba55b228724fd4d4e445f.ttf")
        format("truetype");
}

@font-face {
    font-family: "AuthFont";
    src: url("../../assets/fonts/SukhumvitSet-Light.ttf") format("truetype");
}
@font-face {
    font-family: "AuthFont-Text";
    src: url("../../assets/fonts/SukhumvitSet-Text.ttf") format("truetype");
}
@font-face {
    font-family: "AuthFont-Medium";
    src: url("../../assets/fonts/SukhumvitSet-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "AuthFont-SemiBold";
    src: url("../../assets/fonts/SukhumvitSet-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "AuthFont-Thin";
    src: url("../../assets/fonts/SukhumvitSet-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "AuthFont-Bold";
    src: url("../../assets/fonts/SukhumvitSet-Bold.ttf") format("truetype");
}

@import "font_lato";
@import "font_kometa";
