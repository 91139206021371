@import "theme/variables";
.root {
    border: 1px solid $greyLightest;
    border-bottom-width: 0px !important;
    border-radius: 1px !important;
    background-color: white;
    margin: 5px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
        font-weight: bold;
        font-size: 1.15rem;
    }
}
