.hideScroll {
    overflow: hidden !important;
}
.outer {
    .loader {
        height: 100%;
        width: 100%;
        margin: 0px !important;
        flex-grow: 1;
    }
    .justifyCenter {
        justify-content: centers !important;
    }
    .justifyInitial {
        justify-content: initial !important;
    }
    .mainContent {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: initial;
        height: auto;
        width: 100%;
        margin-top: 7%;
        > div {
            width: 50%;
            max-width: 900px;
            min-width: 370px;
            &:first-child {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .mainContent {
        width: 100% !important;
        height: auto !important;
        padding: 25px 40px;
        > div {
            min-width: 280px !important;
            width: 80% !important;
        }
    }
}

@media only screen and (min-width: 650px) and (max-width: 992px) {
    .mainContent {
        padding: 30px 20px;
    }
}

@media only screen and (max-height: 700px) {
    .mainContent {
        height: auto !important;
    }
}

@media only screen and (min-width: 992px) {
    .mainContent {
        padding: 30px;
        margin-left: 100px;
    }
}
@media only screen and (min-width: 1992px) {
    .mainContent {
        margin-top: 17.5% !important;
    }
}
@media only screen and (min-width: 2600px) {
    .mainContent {
        margin-top: 25% !important;
    }
}
