.root {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.mainHeading {
    font-size: 2.1rem;
    color: black;
    letter-spacing: 1px;
    font-family: "Kometa";
    font-weight: 600;
    margin-bottom: 20px;
}

.circleIconDiv {
    margin: 1vh 0px 2vh 0px;
    margin-bottom: 2px;
    display: flex;
    justify-content: left;
    align-items: center;
    .pinkIcon {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 650px) {
    .mainHeading {
        width: calc(100% + 30px);
        font-size: 1.8rem !important;
        letter-spacing: normal !important;
    }
}

@media only screen and (min-width: 650px) and (max-width: 992px) {
    .mainHeading {
        width: calc(70vw - 80px);
    }
}

@media only screen and (min-width: 992px) {
    .mainHeading {
        width: calc(50vw - 200px);
    }
}
