@import "theme/variables";

.root {
    width: 100%;
    height: 100%;
}
.contentContainer {
    margin-top: -10px;
    width: 100%;
    height: 100%;
    position: relative; // for loader
}
.cardsContainer {
    > * {
        height: 100%;
        margin: 5px 0px;
    }
}
.green {
    color: $green;
}
.primary {
    color: $primary;
}
.darkYellow {
    color: $yellowDark;
}
.red {
    color: red;
}
