@import "theme/variables";

.outer {
    .title {
        font-weight: 300 !important;
    }
    .headerOuter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        .headerDelete {
            display: flex;
            flex-direction: row;
            align-items: center;
            .checkBox input {
                width: 15px;
                height: 15px;
                &:checked + span {
                    background-color: $primary;
                }
            }
        }
        .headerSearch {
            margin-left: auto;
            margin-right: 10px;
            width: 315px;
        }
        .searchField {
            border-color: $greyLightest;
        }
        .searchIconClass {
            z-index: 3 !important;
        }
    }
    .bodyOuter {
        position: relative;
        overflow: hidden;
        .loader {
            min-height: 100px !important;
            height: 100%;
        }
        .indexBody {
            width: 100%;
            height: calc(100vh - 310px);
            max-height: 100%;
            flex-grow: 1;
            min-width: 400px;
            padding-bottom: 10px;
            overflow: auto !important;

            .infiniteScroll {
                overflow: hidden !important;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .indexBody {
        height: calc(100vh - 350px) !important;
    }

    .headerScroll {
        overflow-y: visible;
        overflow-x: visible;
    }
}
