@import "theme/variables";
.icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    background: white;
    border-radius: 50%;
    overflow: hidden;
    &:hover {
        background-color: $primaryLightest !important;
    }
}
.number {
    font-weight: 600;
    font-size: 1rem;
    min-width: 20px;
}
.numberBlue {
    color: $primary;
}
