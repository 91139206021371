@import "theme/variables";
.root {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

.avatars {
    max-width: 120px;
}
.textDiv {
    display: flex;
    margin-left: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    text-transform: none !important;
}
.channelNameText {
    font-weight: bold;
    font-size: 1.2rem;
    color: $textDark;
    word-break: break-word;
}
.channelMemberCountText {
    font-size: 0.9rem;
    margin-top: 5px;
    font-weight: 500;
    color: $greyChateau !important;
}
