@import "theme/variables";

.sortableRow {
    z-index: 1500;
}

.rowsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    > * {
        width: 100%;
    }
}

.rowDragHandle {
    width: 20px;
    background: $greyAccent;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    // margin: -1.5px;
    flex-direction: column;
    padding: 0px 2px;
    justify-content: center;
    cursor: move;
    .icon {
        font-size: 1.1rem;
        color: white;
    }
    &:active,
    &:focus,
    &:focus-within {
        cursor: move;
    }
}
