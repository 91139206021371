.desc {
    margin-bottom: 50px;
    width: 100%;
    font-family: "Lato";
    font-size: 1rem;
    color: black;
    line-height: 21px;
}

.resetButton {
    min-width: 200px;
    height: 59px;
    width: 244px;
    text-transform: uppercase;
}
