@import "theme/variables";

.root {
    display: flex;
    width: calc(100% - 120px) !important;
    flex-direction: column;
    margin-top: 25%;
    .title {
        color: $red;
        font-weight: 600;
        margin: 0px;
        display: block;
        font-size: 2.4rem;
    }
    .text {
        font-size: 1.5rem;
        color: $red;
        margin-top: 15px;
        display: block;
    }
}
