@import "theme/variables";
.root {
    display: flex;
    flex-direction: row;

    .textSection {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 50px !important;
        padding: 6px 4px 6px 25px;
        height: auto;
        border: 1px solid $greyLightest;
        .messageFieldDiv {
            flex-grow: 1;
            margin-right: 10px;
            display: flex;
            .messageTextField {
                border-radius: 10px;
                width: 100%;
                margin: 0px !important;
                outline: none !important;
                border-width: 0px !important;
                box-shadow: none !important;
                padding: 2px !important;
                padding-top: 5px;
                font-size: 0.9rem;
                &::placeholder {
                    color: $greyChateau !important;
                }
            }
        }
        .editCannel {
            cursor: pointer;
            margin-right: 8px;
            margin-left: -15px;
        }
        .iconsSection {
            border-left: 1px solid $greyLightest;
            height: 100%;
            padding: 0 25px;
            display: flex;
            align-items: center;
            // padding-top: 9px;
            justify-content: space-around;
            & > * {
                margin: 2px 5px 2px 10px;
            }

            .emojiIcon {
                margin-left: 15px;
            }
        }
        .icon {
            width: 1.1rem;
            height: 1.1rem;
            object-fit: contain;
        }
    }

    .sendButton {
        max-width: 150px;
        min-width: 140px;
        border-radius: 35px;
        font-size: 0.9rem;
        height: 50px;
        .icon {
            width: 1.1rem;
            height: 1.1rem;
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 1299px) {
    .sendButton {
        padding: 10px !important;
    }
}
