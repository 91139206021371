@import "theme/variables";

.disabled {
    cursor: not-allowed;
    color: $greyAccent !important;
}
.fieldRowRoot {
    border-radius: 4px;
    border: 1px solid $greyAccent;
    display: flex;
    flex-grow: 1;
    margin: 5px 0px;
    flex-direction: row;
    width: 100%;
    .valueField {
        align-items: flex-start !important;
        & > div {
            align-items: center;
        }
    }
    .rowContent {
        position: relative;
        padding: 10px;
        display: flex;
        flex-direction: column;
        > * {
            align-items: flex-start;
            .formRow {
                align-items: center;
            }
        }
        flex-grow: 1;
    }
    .rowDel {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        z-index: 1;
    }
}
@media screen and (min-width: 580px) {
    .fieldRowRoot .valueField .valueSwitch {
        margin-top: 25px;
    }
}
