.filterRoot {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.formGroup {
    padding-right: 5px;
}

@media screen and (min-width: 765px) {
    .paddingRight {
        padding-right: 1.5rem;
    }
}
