$errorLayoutZIndex: 101;
$headerZIndex: 9;
$sidebarZIndex: 10;
$appLayoutZIndex: 12;
$sidebarCloseZIndex: 13;

$headerHeight: 60px;
$sideBarHeight: calc(100vh - $headerHeight);
$appLayoutHeight: calc(100vh - $headerHeight);

$sideBarWidth: 280px;
$sideBarClosedWidth: 80px;
$appLayoutWidth: 100vw;

$messageHeaderHeight: 80px;

$bgMain: #f7f9fa;
$bgHeader: #f1f4f6;
$primaryLightest: #e1f3ff;
$primary: #16aaff;
$blueDimmed: #50a9db;
$primaryDark: #169eff;
$primaryDarkest: #0062cc;
$chatBlue: #23b1fd;

$lightBlue: #d3f0fe;
$lightBlueDark: #e0f3ff;

$purplePrimary: #660099;
$purpleBlueDimmed: #9900cc;
$purpleArchitectUi: #794c8a;
$purpleNew: #8624e1;
$purpleLight: #523c90;
$purpleDark: #32245e;

$textGrey: #9e9e9e;
$textDark: #0d1c26;
$darkGrey: #454257;
$greyAccent: #cccccc;
$greyLightest3: #eee;
$borderGrey: #ced4da;
$greyLight: #999;
$greyChateau: #a0a8ae;
$greyLighter: #dedede;
$greyLightest: #dfdfdf;
$blackHaze: #eaecec; //greyLightest1
$greyLightest2: #efefef;

$tableBody: #fcfcfc;
$green: #2fc47d;
$createGreen: #5ae17d;
$lightGreen: #d3ffcc;
$darkGreen: #3ac47d;

$yellow: #fff69b;
$yellowDark: #ffcc00;
$yellowLight: #ffffd4;
$orangeLight: #f7b924;
$orange: #ffa500;

$red: #da3916;
$redAccent: #c70039;
$errorRed: #d92550;
$errorLightRed: #ff5353;
$lightRed: #ff9e9e;
$lightestRed: #ffcccb;
$lightRed2: #ff7373;

$pink: #ffb6e6;
$darkPink: #d741aa;

$adminNavFont: #228b57;
$adminNavBg: $lightGreen;
$supportNavBg: $lightRed;
$supportNavFont: $red;
$navFontColor: #343a40;

$veniceBlue: #0b5e8e;

$Onahau: #d3f0fe;
$OnahauDark: #e0f3ff;

$messageChatMenuBg: #f9fbfc;

$inputColor: #495057;
