@import "theme/variables";
@import "theme/mixins";

.root {
    position: relative;
    padding: 6px;
}
.menuCard {
    border-radius: 15px;
    @include box-shadow(0, 1.5px, 15px, $greyLightest);
}

.containerMain {
    background: white;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid $greyLightest;
    padding: 20px;
    min-width: 300px;
    max-width: 300px !important;
    margin: 5px 5px 15px 5px;

    .noNotifications {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        color: $textGrey;
        text-align: center;
        font-size: 0.9rem;
    }
    .btnDiv {
        display: flex;
        justify-content: center;
        padding-top: 5px;
    }
}

.navIcon {
    .dotWrapper {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        > span {
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background-color: $primary;
        }
    }
    .navIconImg {
        cursor: pointer;
        width: 21px;
        height: 21px;
        object-fit: contain;
    }
}

@media screen and (min-width: 2000px) {
    .containerMain {
        min-width: 450px;
        max-width: 450px !important;
    }
}
