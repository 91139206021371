@import "theme/variables";
@import "theme/mixins";

.tableRow {
    height: 48px;
}
.textEllipse {
    margin: 0px;
    max-width: 100%;
    @include ellipseText();
}

.cursorPointer {
    cursor: pointer;
}
.green {
    color: $green;
}
.resend {
    color: $primary;
}
