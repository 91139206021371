@import "theme/variables";

.inputContainer {
    width: calc(100% + 60px);
    margin-bottom: 1rem;

    .tooltipContainer {
        width: 60px;
        flex-direction: column;
        .tooltip {
            width: 30px;
            height: 100%;
            display: flex;
            align-items: center;
            float: left;
            img {
                margin-left: auto;
                width: 20px;
                height: 20px;
                object-fit: contain;
                text-align: end;
            }
        }
    }
}

.field {
    padding-left: 42px;
    padding-right: 20px;
    height: 50px;
    border-radius: 4px !important;
    font-family: "Lato";
    box-shadow: none;
}

.textField {
    padding-right: 20px !important;
}

.passwordField {
    padding-right: 40px !important;
}

.iconStart,
.iconEnd {
    position: absolute;
    color: $textGrey;
    left: 18px;
    top: 13px;
    z-index: 5;
}

.iconStart {
    left: 19px;
    img {
        width: 17px;
        height: 17px;
        object-fit: contain;
    }
    span {
        width: 20px;
        height: 20px;
        display: inline-block;
        font-size: 1.3rem;
        color: $textGrey;
    }
}

.iconEnd {
    left: calc(100% - 95px);
    cursor: pointer;
    img {
        width: 18px;
        height: 14px;
        object-fit: contain;
        object-position: center;
        max-height: 14px;
    }
}

.errorMessageDiv {
    width: calc(100% - 60px);
    font-size: 13px;
    font-weight: 400;
}
