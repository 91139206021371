@import "theme/variables";

.input {
    min-height: 44px;

    padding: 14px 15px;
    font-size: 0.9rem;

    border-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

    &.leftPadding {
        padding-left: 24px;
    }

    &.rightPadding {
        padding-right: 26px;
    }
}
.label {
    color: $textDark;
    font-size: 0.9rem;
}
.rightIconDiv > *,
.leftIconDiv > * {
    width: 12px;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    cursor: pointer;
    z-index: 3;
}

.leftIconDiv > * {
    left: 8px;
}

.rightIconDiv > * {
    right: 8px;
}
