@import "theme/variables";

.tableRow {
    height: 48px;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .endDateDiv {
        width: 80px;
    }
}
.bgRed {
    background-color: $lightRed;
    color: black;
    border-radius: 4px;
}
.manualButton {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px !important;
    color: white !important;
    i,
    svg {
        font-size: 0.85rem;
    }
}

.iconName {
    max-width: calc(min(40vw, 520px)) !important;
}
@media screen and (max-width: 2000px) {
    .iconName {
        max-width: 400px !important;
    }
}

@media screen and (min-width: 2800px) {
    .iconName {
        max-width: calc(min(55vw, 1200px)) !important;
    }
}
