@import "theme/variables";
@import "theme/mixins";

.root {
    margin-bottom: 15px;
}
.tableHeading {
    cursor: pointer !important;
    th {
        cursor: pointer !important;
    }
}
.textGrey {
    color: $textGrey !important;
}

.textBlue {
    color: $chatBlue !important;
}

.textRed {
    color: $lightRed2 !important;
}

.displayTableRow {
    display: table-row-group;
}

.displayNone {
    display: none;
}

.toggleIcon {
    text-align: right;
    i,
    svg {
        color: $textGrey;
    }
}
.manualButton {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px !important;
    color: white !important;
    i,
    svg {
        font-size: 0.85rem;
    }
}

.availableStaffHeaderDiv {
    display: flex;
    justify-content: center;
    .availableStaffIconDiv {
        width: 20px;
    }
}
.iconName {
    max-width: calc(min(40vw, 520px)) !important;
}
@media screen and (max-width: 2000px) {
    .iconName {
        max-width: 300px !important;
    }
}

@media screen and (min-width: 2800px) {
    .iconName {
        max-width: calc(min(55vw, 1200px)) !important;
    }
}
