@import "theme/variables";
@import "theme/mixins";
.removeCursor {
    cursor: default !important;
}
.tabAnchorTag {
    margin: 0px !important;
    padding-left: 13px;
    padding-right: 13px;
    min-height: 50px;
    text-decoration: none;
    min-width: 90%;
    max-width: 92% !important;
    border-radius: 50px;
    height: 50px !important;
    text-align: left;
    height: 100%;
    text-align: center;
    color: $textDark !important ;
    font-size: 0.9rem;
    flex-grow: 1;
    font-family: Arial !important;
    font-weight: 500;
    background-color: white !important;
    border-color: $greyLighter !important;
    white-space: nowrap !important;
    &:hover,
    &:focus,
    &:active {
        color: $textDark !important;
        background-color: $primaryLightest !important;
        box-shadow: none !important;
        outline: none !important;
        text-decoration: none;
    }
    &.active {
        font-size: 0.9rem;
        color: white !important;
        background-color: $primary !important;
        font-weight: 600;
        border-color: $primary !important;
        text-decoration: none;
    }
}
.ellipse{
    @include ellipseText();
}
