.root {
    width: 100%;
    padding-bottom: 10px;
}
.slider {
    max-width: 100% !important;
}
.slidePadding {
    padding-left: 90px;
    padding-right: 80px;
}
