@import "theme/variables";
@import "theme/mixins";
.root {
    width: 100%;
    border: 1px solid $greyLightest;
    overflow: hidden;

    > div {
        width: 100%;
        overflow: hidden;
    }

    @include overflowOverlay();
    thead tr th {
        color: $textGrey !important;
        font-weight: 300;
        text-align: start;
        z-index: 4;
    }
    tbody tr {
        &:not([class*="row-with-innerTable"]) {
            
                height: 45px !important;
                min-height: 45px;
            
        }
        &[class*="react-window-dummy-row"] {
            height:0px !important
        }
        & *[class*="-input-icon"] {
            > * {
                // z-index: 1 !important;
            }
        }
        max-height: 45px;
        td {
            text-align: start;
        }
    }
    tr[class*="filters-row"] {
        cursor: default !important;

        > td {
            &:not([class*="default-padding"]) {
                padding-left: 10px;
                padding-right: 10px;
            }
            cursor: default !important;
            [class*="form-group"] {
                margin: 0px;
                input {
                    padding-left: 10px !important;
                }
            }
            input {
                &:not([id*="react-select"]) {
                    min-height: 39px !important;
                }
            }
        }
    }
}
.noBottomBorder {
    table:first-child > thead {
        border-bottom-width: 0px !important;
    }
}
.textEllipse {
    width: 300px;
    vertical-align: middle;
    @include ellipseText();
}
.table {
    width: 100%;
    table-layout: fixed;
    min-width: 100%;
    overflow: hidden !important;
    margin-bottom: 0;
    background-color: white;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0px;
    position: relative;
    &.stickyHeader {
        overflow: initial !important;
        @-moz-document url-prefix() {
            // on firefox it causes issue
            overflow: hidden !important;
        }
        > thead {
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 4;
        }
        // @supports (-webkit-tap-highlight-color: black) {
        //     /* Matches Edge 12 - latest (92) */
        //     /* Matches Chrome 16 - latest (95) */
        //     /* Matches Opera 15 - latest (78) */
        //     /* does not match Safari */
        //     /* does not match Firefox */
        // }
        tr[class*="filters-row"] {
            z-index: 3;
            td {
                position: sticky;
                top: 60px;
            }
            &,
            td {
                &,
                > div {
                    z-index: 3 !important;
                }
                background-color: white !important;
            }
        }
    }
    @-moz-document url-prefix() {
        // after new update table padding is not working correctly on chromium browsers
        border-collapse: collapse !important;
    }
    > thead {
        border-width: 0px !important;
        th {
            border-width: 0px !important;
        }
    }
    &:not(.styleHeader) {
        th {
            padding: 0px !important;
        }
    }
    &.styleHeader {
        &:not(.stickyHeader) {
            > thead {
                @supports (-webkit-tap-highlight-color: black) {
                    /* Matches Edge 12 - latest (92) */
                    /* Matches Chrome 16 - latest (95) */
                    /* Matches Opera 15 - latest (78) */
                    /* does not match Safari */
                    /* does not match Firefox */
                    @include box-shadow(0, 1.5px, 15px, $greyLightest);
                }
                @-moz-document url-prefix() {
                    // firefox
                    @include box-shadow(0, 1.5px, 15px, $greyLightest);
                }

                @supports (-webkit-hyphens: none) {
                    // shadow on tr not working for safari
                    th {
                        @include box-shadow(0px, 1.5px, 15px, $greyLightest);
                        // only show shadow on bottom
                        clip-path: polygon(0% 0%, 100% 0%, 100% 125%, 0% 125%);
                    }
                }
            }
        }
        &.stickyHeader {
            > thead {
                @include box-shadow(0px, 1.5px, 15px, $greyLightest);
                // th {
                //     // only show shadow on bottom
                //     clip-path: polygon(0% 0%, 100% 0%, 100% 125%, 0% 125%);
                // }
            }
        }

        > thead {
            background-color: white !important;
            align-items: center;
            width: 100%;
            user-select: none;
            cursor: default;

            > tr {
                height: 60px;
                td,
                th {
                    cursor: default;
                    vertical-align: middle;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1rem;
                    border-top: 0px solid transparent !important;
                    @media not all and (min-resolution: 0.001dpcm) {
                        border-bottom: 1px solid $greyLightest !important;
                    }
                }
                > &:not(:first-child) {
                    td,
                    th {
                        color: $textGrey !important;
                    }
                }
            }
        }
    }
    td,
    th {
        vertical-align: middle;
        padding-left: 20px;
        padding-right: 20px;
        font-family: Arial, Helvetica, sans-serif;
    }
    td {
        border-left: 0px solid white;
        border-right: 0px solid white;
    }
    > tbody {
        tr {
            td,
            th {
                border-top: 1px solid $greyLightest;
            }
            &:hover {
                background-color: $primaryLightest;
            }
            &:nth-child(2) {
                > td {
                    border-width: 0px !important;
                }
            }
            &:first-child,
            &:nth-child(2),
            &:last-child {
                &:not([class*="filters-row"]) {
                &:not([class*="react-window-dummy-row"]) {
                    // first 2 rows and last row is dummy just to add spacing
                    height: 0px !important;
                    background-color: transparent !important;
                    &:nth-child(2) > td {
                        padding: 0px !important;
                    }
                    &:not(:last-child) {
                        & > td {
                            border-top-width: 0px !important;
                        }
                        height: 2px !important;
                    }
                }
                }
            }
            &:not(:first-child) {
                > td {
                    &:first-child {
                        @-moz-document url-prefix() {
                            // after new update table padding is not working correctly on chromium browsers
                            border-left: 25px solid white !important;
                        }
                        border-left: 15px solid white !important;
                        border-top-left-radius: 15px;
                        padding-left: 0px;
                    }
                    &:last-child {
                        border-right: 15px solid white !important;
                        border-top-right-radius: 15px;
                        @-moz-document url-prefix() {
                            // after new update table padding is not working correctly on chromium browsers
                            border-right: 25px solid white !important;
                        }
                    }
                }
            }
            table {
                td:first-child {
                    padding-left: 0px;
                }
                td:last-child {
                    padding-right: 0px;
                }
            }
        }
    }
}
.showCursor {
    > tbody {
        > tr:not([class*="dummy-row"]) {
            &:not([class*="filters-row"]) {
                > td,
                > th {
                    cursor: pointer !important;
                }
            }
        }
    }
}

.hideCursor {
    > tbody > tr {
        > td,
        > th {
            cursor: default !important;
        }
    }
}
