textarea[type="text"] {
    height: auto !important;
}

.form-control:focus {
    color: $textDark;
    background-color: white;
    border-color: $primary;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $primaryLightest;
}

.form-control::placeholder {
    color: $textGrey;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: $greyLightest3;
    opacity: 1;
}

input,
textarea,
select {
    &:-webkit-autofill,
    &:-webkit-autofill::first-line,
    &:-moz-autofill,
    &:-moz-autofill-preview,
    &:-internal-autofill-selected {
        -webkit-text-fill-color: black !important;
        -moz-text-fill-color: black !important;
        box-shadow: 0 0 0px 1000px $yellow inset !important;
        -webkit-box-shadow: 0 0 0px 1000px $yellow inset !important;
        transition: background-color 5000s ease-in-out 0s !important;

        &:hover,
        &:active,
        &:focus {
            -webkit-text-fill-color: black !important;
            -moz-text-fill-color: black !important;
            -webkit-box-shadow: 0 0 0px 1000px $yellow inset !important;
            box-shadow: 0 0 0px 1000px $yellow inset !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }
    }
}

@import "./customCheckbox";
