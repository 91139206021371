.infiniteScroll {
    overflow: hidden !important;
}

.tableClass {
    min-width: 900px !important;
}

.displayId {
    min-width: 70px;
    width: 70px;
    max-width: 70px;
    padding: 0px !important;
    margin: 0px;
    text-align: center !important;
    padding-left: 20px !important;
}
