@import "theme/variables";
.root {
    width: 100%;
}
.scrollLoader {
    position: absolute;
    height: 100% !important;
    margin-left: -1rem;
    width: 100%;
}
.bodyRoot {
    padding-top: 10px;
    padding-right: 10px;
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
}
.chatMessagesContainer {
    width: 100%;
    position: relative;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .infiniteScroll {
        overflow: hidden !important;
    }
}
.typingDiv {
    color: $greyChateau;
    margin-bottom: 5px;
    width: 100%;
    .blockText {
        color: $redAccent;
    }
}
.stickyRoot {
    display: flex;
    width: 100%;
    justify-content: center;
    .stickyDiv {
        text-align: center;
        color: $greyChateau;
        border-radius: 25px;
        padding: 0 10px;
        border: 1px solid $greyChateau;
        background-color: white;
    }
}
