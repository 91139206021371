@import "theme/variables";
@import "theme/mixins";

.userIconName {
    display: flex;
    max-width: calc(min(50vw, 100vw - 600px));
    align-items: center;
}
.userIconSpan {
    margin-right: 8px;
    .userIcon {
        width: 1.3em;
        height: 1.3em;
        object-fit: contain;
    }
}

.textEllipse {
    margin: 0px;
    max-width: 100%;
    color: $textDark;
    @include ellipseText();
}

@media screen and (max-width: 1700px) {
    .userIconName {
        max-width: calc(min(50vw, 400px)) !important;
    }
}
@media screen and (min-width: 2800px) {
    .userIconName {
        max-width: calc(min(70vw, 100vw - 600px)) !important;
    }
}
