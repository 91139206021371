@import "theme/variables";

.root {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    .iconContainer {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        .icon {
            font-size: 2rem;
            color: $primary;
            margin-top: -40px;
        }
    }
    .titleOfOverlay {
        padding: 5px 5px;
        font-weight: 600;
        font-size: 1rem;
        color: white;
    }
}
