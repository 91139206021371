@import "theme/variables";
@import "theme/mixins";
.textField {
    padding: 20px 25px;
    padding-left: 54px;
    border-width: 1px;
    border-color: white;
    font-size: 1rem !important;
    border-radius: 30px !important;
    @include box-shadow(1px, 4.5px, 10px, rgba(0, 0, 0, 0.05));
    &:focus {
        border-color: $primary !important;
    }
    &::placeholder {
        color: $textGrey;
        opacity: 1;
    }
}
.icon > img {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 12px;
    left: 25px;
    z-index: 5;
    object-fit: contain;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
