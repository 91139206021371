.navIcon {
    cursor: pointer !important;
    margin-right: 4px;
    img {
        width: 22px;
        height: 22px;
        object-fit: contain;
    }
}

.root {
    width: 100vw;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 43px;
    background: rgba(0, 0, 0, 0.5);
    margin-top: 17px;

    .container {
        width: 85.5%;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: flex-end;
    }
}
@media screen and (min-width: 2500px) {
    .container {
        width: 89% !important;
    }
}
