.loader {
    width: 18px !important;
    height: 18px !important;
    margin-right: 30px;
    border-width: 2px !important;
}
.cloneImage {
    cursor: pointer;
    width: 18px !important;
    height: 18px !important;
    object-fit: contain;
}
