@import "theme/variables";

.main {
    font-weight: 600;
}
.red {
    color: $red;
}

.green {
    color: $green;
}
