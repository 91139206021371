@import "theme/variables";
.searchField {
    &::placeholder {
        color: $textGrey;
        opacity: 1;
    }
}
.tagTitle {
    font-size: 1rem;
    color: $textDark;
}
.root {
    padding: 0px 10px;
}

@media only screen and (max-width: 800px) {
    .searchDiv {
        margin-top: 10px;
    }
}
@media only screen and (max-width: 750px) {
    .root {
        align-items: flex-end;
        flex-direction: column;
    }
}
