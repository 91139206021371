@import "theme/variables";
.root {
    border-radius: 0px;
    border-width: 0px;
}
.chatEmptyTextDiv {
    width: 100%;
    text-align: center;
    color: $textGrey;
}
