.outer {
    position: fixed;
    top: 0;
    &.openInCurrentTab {
        top: 60px !important;
    }
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background-color: white;

    .crossImageDiv {
        position: absolute;
        top: 75px;
        right: 40px;
        cursor: pointer;

        > img {
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }
}
