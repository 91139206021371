@import "theme/variables";
.progressSpinner {
    display: flex;
    min-width: 150px;
    height: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .spinnerIcon {
        width: 50px;
        height: 50px;
        border-width: 5px;
        color: $primary;
        &.large {
            width: 150px;
            height: 150px;
        }
    }
}
.fullHeight {
    min-height: 75vh !important;
    height: 100% !important;
}
.apiErrorMessage {
    font-size: 1rem;
    color: $red;
}
