@import "theme/variables";

.progressChild {
    font-family: Arial, Helvetica, sans-serif;
    width: 50px;
    height: 50px;
    margin: 4px;
    border-radius: 50%;
    background-color: $blackHaze;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: $textDark;
}
