@import "theme/variables";

.root {
    border: 1px solid $greyLightest;
    width: 48%;
    margin: 0px 20px;
    min-width: 550px;
    max-width: 850px;
}
.userIcon {
    width: 25px;
    height: 20px;
    object-fit: contain;
    object-position: left;
    margin-right: 10px;
}

.mobileIcon {
    width: auto;
    height: 25px;
    object-fit: contain;
}

.userText {
    font-weight: 600;
    color: black;
    font-size: 15px;
}

.inputFieldDash {
    font-size: 20px;
    display: inline-block;
    margin: 0 20px;
}
.mainSection {
    display: flex;
    > div:first-child {
        // for image
        overflow: hidden;
        width: 40%;
        min-width: 200px !important;
        height: auto;
        margin: -23px;
        margin-right: 5px !important;
        max-height: 210px;
        display: flex;
        justify-content: center;
        align-items: center;
        .qrCodeImage {
            width: 99%;
            height: auto;
            max-height: 220px !important;
            object-fit: contain;
        }
    }
    > div:last-child {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        > .actions {
            display: flex;
            margin-top: 15px;
            align-items: center;
            justify-content: space-between;

            .refreshBtn {
                padding: 9px 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                .refreshIcon {
                    width: 18px;
                    height: 18px;
                    object-fit: contain;
                }
                .refreshText {
                    font-size: 15px;
                    margin-right: 5px;
                }
            }

            .btnCircle {
                width: 55px;
                height: 55px;
                padding: 15px;
            }
            .pdfBtn {
                margin-left: 20px;
                &,
                &:hover,
                &:active,
                &:visited,
                &:focus,
                &:focus-within,
                &:target,
                &:disabled {
                    background-color: $green;
                    border: 0px;
                }
            }
        }
    }
}
.codeText {
    display: inline-block;
    font-size: 16px;
    min-height: 42px;
    min-width: 125px;
    letter-spacing: 10px;
    color: black;
    border: 1px solid $greyLightest;
    font-weight: 600;
    border-radius: 4px;
    padding: 8px 10px 8px 22px;
}
