@import "theme/variables";
.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 20px;
    > div {
        width: 100%;
    }
}
.tabsContainer {
    display: flex;
    flex-direction: column;
    margin: 0px;
    margin-top: 50px;
    .tabWrapper {
        flex-grow: 1;
        display: block;
        opacity: 1;
    }
}
.buttonsContainer {
    border-radius: 0px;
    overflow: hidden;
    display: flex;
    padding: 0px !important;
    position: relative;
    height: 60px;
    background-color: transparent;
    .tabButton {
        margin: 0px !important;
        padding: 12px 0px;
        text-align: left;
        height: 100%;
        color: $textGrey;
        font-size: 1.28rem;
        flex-grow: 1;
        font-family: "Lato", sans-serif !important;
        font-weight: 300;
        background-color: transparent !important;
        border-width: 0px !important;
        transition: color 0.25s 0.3s, font-size 0.2s 0.1s,
            font-weight 0.25s 0.2s;
        &:hover,
        &:focus,
        &:active {
            color: $textDark;
            background-color: transparent !important;
            box-shadow: none !important;
            outline: none !important;
        }
        &.active {
            color: $textDark !important;
            font-size: 1.55rem;
            font-weight: 600;
            border-bottom: 2px solid $textDark;
        }
    }
    .tabBar {
        height: 2px;
        position: absolute;
        bottom: 0px;
        transition: left 0.3s ease-in;
        border-width: 0px !important;
    }
    .initialTabsBar {
        width: 100%;
        z-index: 0;
        background-color: $textGrey;
    }
    .activeSelector {
        z-index: 1 !important;
        background-color: $textDark !important;
    }
}

.animated {
    .tabSwitching {
        animation-name: fade;
        animation-duration: 0.3s;
    }
    .tabButton {
        z-index: 1;
        &:not([class*="active"]) {
            &:active,
            &:focus {
                color: $textDark !important;
            }
        }
        &:active,
        &:focus,
        &.active {
            background-color: transparent !important;
        }
    }
}
@keyframes fade {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 650px) {
    .tabButton {
        font-size: 1.18rem !important;
        &.active {
            font-size: 1.3rem !important;
        }
    }
}
