@import "theme/variables";

.parentFlags {
    padding-right: 10px;
    flex-grow: 1;
    border-right: 1px solid $borderGrey;
}
.title {
    font-weight: bold;
    color: $textDark;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-top: 15px;
}
.hrLine {
    margin: 15px 0px;
    border-color: $borderGrey;
}
.switch {
    margin-bottom: 5px !important;
}
.checkboxContainer {
    display: flex;
    align-items: center;
    .inlineCheckbox {
        display: inline-flex;
        margin-left: -5px;
        margin-right: 15px;
    }
    label {
        margin: 0px !important;
    }
}
@media only screen and (max-width: 990px) {
    .parentFlags {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $borderGrey;
        border-right: 0px solid $borderGrey;
    }
}
