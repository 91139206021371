@import "theme/variables";
@import "theme/mixins";

.root {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dfdfdf;
    height: calc(100vh - 185px);
    @include overflowOverlay();
    @-moz-document url-prefix() {
        // for firefox show all borders because table borders are not shown
        border: 1px solid #dfdfdf;
    }
}
.tableRoot {
    overflow: visible !important;
    border: 0px solid !important;
    table {
        border: 1px solid #dfdfdf;
        border-top: 0px solid white;
        thead tr:last-child th {
            top: 40px !important;
        }
    }
    thead th {
        padding: 5px 10px !important;
    }
    .tableHeading {
        .monthHeaderCell {
            span {
                display: flex;
                width: 100%;
                padding: 3px 10px;
                background-color: $lightBlue;
                border-radius: 4px;
                justify-content: center;
                color: $primary;
                font-weight: 900;
            }
        }
        tr.secondRow {
            th {
                &:not(:first-child) {
                    padding: 2px 5px;
                }
                font-size: 0.9rem !important;
                font-weight: 500;
            }
        }
    }
    tbody tr td {
        padding: 5px;
        text-align: center;
        &:first-child {
            padding: 5px 10px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .root {
        height: calc(100vh - 230px);
    }
}
