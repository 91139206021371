@import "theme/variables";

.parentFlags {
    padding-right: 10px;
    border-right: 1px solid $borderGrey;
}
.btn {
    width: 100px;
    &:first-child {
        margin-right: 20px;
    }
}

.cancel {
    &,
    + &:hover,
    &:focus,
    &:active {
        background-color: white !important;
    }
}
.title {
    font-weight: bold;
    color: $textDark;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

@media only screen and (max-width: 990px) {
    .parentFlags {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $borderGrey;
        border-right: 0px solid $borderGrey;
    }
}
@media only screen and (max-width: 1620px) {
    .switch {
        height: 44px;
    }
}
