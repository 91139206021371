@import "theme/variables";
@import "theme/mixins";

.detailOuter {
    flex: 1;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;

    .headerRoot {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        min-height: $messageHeaderHeight;
        align-items: center;

        .content {
            padding-left: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-grow: 1;
            height: 100%;
        }

        .headerImage {
            border-radius: 50% !important;
            overflow: hidden;
            min-width: 45px;
            width: 45px;
            height: 45px;
            object-fit: contain;
        }
        .headerText {
            margin-left: 15px;
            margin-right: 15px;
            width: auto;
            max-width: 350px;
            font-weight: 600;
            vertical-align: middle;
            font-size: 1.15rem;
            color: $textDark;
            @include ellipseText();
        }
        .toContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: none;
            flex-grow: 1;
            .toChip {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 15px;
                padding: 3px 15px;
                background-color: $greyLightest2;
                width: auto;
                span {
                    @include ellipseText();
                    color: $textDark;
                    font-size: 0.9rem;
                }
            }
        }
        .headerDate {
            padding: 5px 0;
            margin-left: auto;
            text-align: right;
            margin-right: 11px;
            max-width: 300px;
            font-size: 0.85rem;
            color: $textGrey;
            font-weight: 300;
        }
    }
}

.detailBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    overflow: auto;

    .imageSection {
        padding: 5px;
        .attachmentsSection {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            min-height: 110px;
        }
    }
}
.messageContent {
    padding: 0 12px;
    padding-bottom: 10px;
    flex-grow: 1;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    .detailTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.3rem;
        font-weight: 600;
        color: $textDark;
        p {
            margin: 0px !important;
        }
        .attachments {
            margin-left: 10px;
            img {
                width: 18px;
                height: 18px;
                object-fit: contain;
            }
        }
        .titleRight {
            display: flex;
            margin-left: auto;
            span {
                margin-left: 20px;
                img {
                    cursor: pointer;
                    width: 18px;
                    height: 18px;
                    object-fit: contain;
                }
            }
        }
    }
    .detailDesc {
        color: $textDark;
        margin-top: 25px;
        flex-grow: 1;
        min-height: 130px;
        font-size: 1rem;
        max-height: calc(100vh - 610px);
        overflow-y: auto;
        p {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 801px) {
    .detailDesc {
        height: calc(100vh - 660px) !important;
    }
}

@media only screen and (max-width: 800px) {
    .detailDesc {
        height: calc(100vh - 610px) !important;
    }
}

@media only screen and (max-width: 500px) {
    .headerImage {
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
        & > div {
            font-size: 0.85rem !important;
        }
    }

    .headerText {
        margin-left: 15px;
        font-size: 0.9rem !important;
        max-width: 150px !important;
    }

    .headerDate {
        padding: 2px 0 !important;
        margin-right: 5px !important;
        font-size: 0.7rem !important;
    }
}
