@import "theme/mixins";
.root {
    min-width: 2125px !important;
}

.tableRoot {
    @include overflowOverlay();
    table {
        table-layout: auto !important;
    }
}
