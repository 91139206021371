@import "theme/variables";
.root {
    display: flex;
    border-bottom: 1px solid $greyLighter;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 15px;
    padding-bottom: 0px !important;
    align-items: flex-start;

    .explanation {
        font-style: italic;
        color: $textGrey;
        font-size: 0.9;
    }
}

.colorLine {
    border-radius: 2px;
    height: 40px;
    width: 5px;
    margin-top: 3px;
    min-width: 5px !important;
}
.title {
    font-weight: 600;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
