.root {
    margin-bottom: 5px;
    padding: 12px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .left {
        width: 55px;
    }
    .middle {
        flex-grow: 1;
        margin-left: 20px;
        margin-right: 15px;
    }
    .right {
        width: 40px;
    }
}
