@import "theme/variables";

.container {
    max-width: 500px;
    max-height: 300px;
    overflow-y: auto;
}
.table {
    table {
        border-collapse: collapse;
        border: none;
        tr {
            background-color: transparent !important;
            &::hover {
                background-color: transparent !important;
            }
            td,
            th {
                margin: 0px;
                text-align: center;
                padding: 3px 5px;
                border-left: none;
                border-right: none;
            }
        }
    }
    td {
        border: none;
    }
    td:first-child {
        text-align: start;
        word-wrap: break-word;
    }
}
