@import "theme/variables";
@import "theme/variables";
@import "theme/mixins";

.itemOuter {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px 0px;
    border-bottom: 1px solid $greyLightest;
    > * {
        max-height: 100%;
    }
    .itemLeft,
    .itemRight {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
.content {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .circleImage {
        min-width: 50px;
        width: 50px;
        height: 50px;
    }
    .textDiv {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        .senderName {
            text-align: left;
            font-size: 1rem;
            color: $textDark;
            font-weight: 600;
            @include ellipseText();
        }

        .senderMessage {
            @include ellipseText();
            font-size: 0.9rem;
            margin-top: 5px;
            color: $textDark;
            p {
                padding: 0;
                margin: 0;
            }
        }
    }
}
.itemLeft {
    .checkBox {
        input {
            width: 15px;
            height: 15px;
            &:checked + span {
                background-color: $primary;
            }
        }
    }
    .iconStar {
        cursor: pointer;
        margin: 0 15px;
        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }
    }
}
.itemRight {
    align-items: flex-start !important;
    min-width: 145px;
    .attachments {
        margin-left: auto;
        padding: 0 10px;
        display: block;
        height: 50%;
        img {
            width: 15px;
            height: 15px;
        }
    }
    .date {
        display: block;
        height: 50%;
        font-size: 0.8rem;
        color: $textGrey;
    }
}
