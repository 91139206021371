@import "theme/variables";
.root {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .createBtn {
        width: 255px;
    }
}
.content {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
