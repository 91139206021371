@import "theme/variables";
.root {
    color: $primary !important;
    display: flex;
    align-items: center;

    .btnWidth {
        width: 130px;
    }
    &,
    * {
        outline: none !important;
        box-shadow: none !important;
    }
    &:hover {
        text-decoration: none;
    }
}

.hoverEffect {
    .arrow:hover {
        background-color: $lightBlue;
        svg,
        i {
            color: $textDark;
        }
    }
}
.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $primary;
    svg,
    i {
        color: white;
        font-size: 1.1rem;
    }
    img {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
}
.backText {
    margin-left: 12px;
    font-size: 1rem;
    display: inline-flex;
}

.button {
    padding-left: 0;
}
