@import "theme/variables";
@import "theme/mixins";

.itemOuter {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: auto;
    min-height: 75px;
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
    border-bottom: 1px solid $greyLightest;
    > * {
        max-height: 100%;
    }
    .itemLeft,
    .itemRight {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}
.itemLeft {
    .checkBox {
        margin-top: 11px;
        margin-right: 22px;
        input {
            width: 15px;
            height: 15px;
            &:checked + span {
                background-color: $primary;
            }
        }
    }
    .iconStar {
        cursor: pointer;
        margin: 0 15px;
        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }
    }
}
a.content {
    text-decoration: none;
    display: flex;
    max-width: calc(100% - 250px);
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
    .cursorPointer {
        cursor: pointer;
    }
    .imgSpan {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px 8px;
        min-width: 42px;
        width: 42px;
        height: 42px;
        img {
            width: 19px;
            height: 19px;
            object-fit: contain;
        }
    }
    .circleImage {
        min-width: 50px;
        width: 50px;
        height: 50px;
    }
    .textDiv {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        max-width: calc(100% - 50px);
        color: black;
        p,
        .description {
            margin: 0;
            padding: 0;
        }
        .title {
            padding-bottom: 5px;
            text-align: left;
            font-size: 1rem;
            color: $textDark;
            font-weight: 600;
            opacity: 0.8;
            line-height: 1;
            width: 100%;
            @include ellipseText();
        }
    }
}
.itemRight {
    align-self: flex-start;
    min-width: 180px;
    display: flex;
    .dateAndNewDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.8rem;
        color: $textGrey;
        white-space: nowrap;
        margin: 0px;

        .newText {
            background-color: $green;
            color: white;
            font-size: 9px;
            padding: 2px 8px 1px;
            max-width: 50px;
            border-radius: 3px;
            text-transform: uppercase;
        }
    }
    .optionMenu {
        margin-top: -7px;
        margin-left: auto;
        img {
            width: 18px;
            height: auto;
            object-fit: contain;
        }
    }
}
.bgRed {
    background-color: red;
}
.listItem {
    padding: 3px 5px;
    border-bottom: 1px solid $greyLightest3;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:last-child {
        border-bottom: none;
    }
    &:hover {
        background-color: $OnahauDark;
        transition: all 0.3s ease-in-out;
    }
}
