@import "theme/variables";
.header {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.15rem;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    img {
        margin-right: 10px;
        width: 40px;
        height: 40px;
    }
}
