@import "theme/variables";
@import "theme/mixins";

.root {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px);
    @include overflowOverlay();
}

.table {
    thead th {
        padding: 2px !important;
    }
    td {
        &:not(:first-child) {
            text-align: end;
            padding-right: 20px;
        }
    }
    tbody tr {
        td:last-child {
            padding-right: 35px;
        }
    }
    .totalAGKids {
        margin-left: 138px;
        width: 80px;
        padding: 0px 5px;
        text-align: center;
    }
    .totalValue {
        font-weight: 600;
    }
}
