@import "theme/variables";

.gradientButton {
    background-image: linear-gradient(to right, $darkPink, $primary);
    border-radius: 8px !important;
    text-align: center;
    color: white;
    padding: 10px 15px;
    font-size: 1rem;
    letter-spacing: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lato";
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    border-width: 0px !important;
    &:hover,
    &:focus,
    &:active {
        color: white !important;
        &:not(.animate) {
            opacity: 0.9 !important;
        }
    }
}

.animate {
    transition: 0.2s all;
    &:active {
        transform: scale(0.92);
        box-shadow: 0 0 0 100vmax inset rgba(0 0 0 / 30%) !important;
        // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
}
@media only screen and (max-width: 650px) {
    .gradientButton {
        margin: 0 auto;
    }
}
