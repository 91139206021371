@import "theme/mixins";
@import "theme/variables";

.popupDiv {
    min-width: 50px;
    width: 50px;
    max-width: 100px;
}
.absentsInfoTable {
    td,
    th {
        padding-left: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 5px;
        font-size: 0.8rem;
        cursor: default !important;
    }
    td {
        text-align: left;
    }
}
