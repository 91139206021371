.imageAndDesc {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    align-items: center;
    img {
        width: 42px;
        height: 42px;
        object-fit: contain;
    }
    .desc {
        padding-left: 15px;
        width: 100%;
        font-family: "Lato";
        font-size: 1rem;
        color: black;
        line-height: 21px;
        display: flex;
        align-items: center;
    }
}

.confirmationButton {
    min-width: 200px;
    height: 59px;
    width: 244px;
    text-transform: uppercase;
}
