.icon {
    img {
        height: 32px !important;
        width: 32px !important;
        object-fit: contain;
    }
}
.displayId {
    padding: 0px !important;
    margin: 0px;
    text-align: center !important;
}

.displayFiled {
    width: 50px;
    height: 25px;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.9rem;
    text-align: center;
}
