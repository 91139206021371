@import "theme/variables";
.lineTd {
    width: 1px;
    height: 100%;
    max-width: 1px;
    min-width: 1px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    div {
        width: 1px;
        background-color: $greyLightest;
        padding-bottom: 55px;
    }
}
.headerBack {
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $Onahau;
    cursor: pointer;
    &:hover {
        background-color: $primary;
    }
    img {
        object-fit: contain;
        width: 16px;
        height: 16px;
    }
}
