@import "theme/variables";

.root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    margin: 5px 0px;
    > * {
        width: 225px;
        min-width: 225px;
    }
}
