@import "theme/variables";

.infiniteScroll {
    overflow: hidden !important;
}
.table {
    table-layout: auto;
}
.displayId {
    min-width: 70px;
    width: 70px;
    padding: 0px !important;
    margin: 0px;
    text-align: center !important;
    padding-left: 20px !important;
}
