@import "theme/variables";

.outer {
    display: flex;
    align-items: center;

    .docAndPdfImages {
        cursor: pointer;
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-left: 15px;
    }

    .createButtonDiv {
        width: 240px;
        min-width: 240px;
        max-width: 270px;
        margin-left: 20px;
        a {
            cursor: pointer;
            flex-grow: 1;

            padding: 12px 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            height: auto;
            color: white !important;
            background-color: $createGreen;
            font-size: 1.05rem;
            border-radius: 30px;
            border-width: 0px;
            text-decoration: none;

            .createNewImage {
                height: 15px;
                width: 15px;
                object-fit: contain;
                margin-left: 10px;
            }
        }
    }

    .btnCircle {
        width: 40px;
        height: 40px;
        padding: 6px;
    }
}
