@import "theme/variables";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .endDateDiv {
        width: 80px;
    }
}
