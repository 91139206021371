@import "theme/variables";

.root {
    width: 450px;
}
.triggerIcon {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    color: $primary;
}

.title {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 20px !important;
    font-weight: bold;
}
.underline {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: $greyLightest;
}

.header {
    padding-top: 3px;
    padding-bottom: 3px;
    .iconHelp {
        padding-right: 10px;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }

    .mainHeading {
        padding-top: 1px;
        padding-bottom: 2px;
        font-size: 1.3rem;
        font-weight: 600;
        color: black;
    }
}
.icon {
    margin-right: 11px !important;
}
.body {
    padding-top: 15px;
    max-height: calc(min(650px, calc(60vh - 150px)));
    overflow-y: auto;
    overflow-x: hidden;
    td {
        cursor: default !important;
        padding-left: 2px;
    }
}
