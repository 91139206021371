@import "theme/variables";
@media screen and (min-width: 580px) {
    .fieldRowRoot .valueField .valueSwitch {
        margin-top: 25px;
    }
}

.rowDragHandle {
    width: 20px;
    background: $greyAccent;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    margin: -1.5px;
    flex-direction: column;
    padding: 0px 2px;
    justify-content: center;
    cursor: move;
    .icon {
        font-size: 1.1rem;
        color: white;
    }
    &:active,
    &:focus,
    &:focus-within {
        cursor: move;
    }
}
.disabled {
    cursor: not-allowed;
    color: $greyAccent !important;
}
.fieldRowRoot {
    border-radius: 4px;
    border: 1px solid $greyAccent;
    display: flex;
    flex-grow: 1;
    margin: 5px 0px;
    flex-direction: row;
    width: 100%;
    .valueField {
        align-items: flex-start !important;
        & > div {
            align-items: center;
        }
    }
    .rowContent {
        position: relative;
        padding: 10px;
        display: flex;
        flex-direction: column;
        > * {
            align-items: flex-start;
            .formRow {
                align-items: center;
            }
        }
        flex-grow: 1;
    }
    .rowDel {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 15px;
        height: 20px;
        cursor: pointer;
        z-index: 1;
        .deleteIcon,
        svg {
            color: $red;
            font-size: 1.1rem;
            &:hover {
                color: $redAccent;
            }
        }
    }
}
