@import "theme/mixins";
@import "theme/variables";

.fullHeight {
    height: 100% !important;
}

.loadingOverlay {
    position: absolute;
    height: 100%;
    display: block !important;
    width: 100%;
    z-index: 90;
    overflow: hidden;
    // pointer-events: none !important;
    cursor: not-allowed !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
.blur {
    @include blur(10px);
    background: rgba(0, 0, 0, 0.05);
}
.opaque {
    background: $bgMain !important;
}
