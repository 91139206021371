@import "theme/variables";
@media screen and (max-width: 1500px) {
    .count {
        font-size: 2.5rem !important;
    }
}
@media screen and (max-width: 1000px) {
    .count {
        font-size: 2rem !important;
    }
}
.ACTIVE_CONTRACTS .count {
    color: $green;
}
.STARTING_CONTRACTS .count {
    color: $primary;
}
.ENDING_CONTRACTS .count {
    color: $yellowDark;
}

.count {
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    line-height: 1.2;
    flex-grow: 1;
    overflow: hidden;
    max-height: 75px;
}
.icon {
    margin-top: 10px;
    margin-bottom: auto;
    margin-left: 10px;
    display: flex;
    cursor: pointer;
    img {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
}
.heading {
    font-size: 1.05rem;
    min-height: 16px;
    color: $greyLight;
    line-height: 1;
    margin-bottom: 5px;
}

.marginTopSix {
    margin-top: 6px;
}

@media screen and (max-width: 1350px) {
    .heading {
        min-height: 64px;
    }
}

@media screen and (min-width: 1350px) and (max-width: 1700px) {
    .heading {
        min-height: 48px;
    }
}

@media screen and (min-width: 1701px) and (max-width: 2600px) {
    .heading {
        min-height: 32px;
    }
}
@media screen and (min-width: 1434px) and (max-width: 1526px) {
    .heading {
        min-height: 64px;
    }
}
