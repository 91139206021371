@import "theme/mixins";
.displayId {
    padding-left: 20px !important;
    margin: 0px;
}
.textEllipse {
    margin: 0px;
    max-width: 300px;
    @include ellipseText();
}
.functionTextEllipse {
    margin: 0px;
    max-width: 150px;
    margin-bottom: -5px;
    @include ellipseText();
}

.mobileImage {
    width: 30px;
    height: 22px;
    object-fit: contain;
    object-position: center;
}
.textFields {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
