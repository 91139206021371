@import "theme/mixins";
body {
    margin: 0px !important;
}
.btn {
    font-weight: 500;
}
.app_main__outer {
    transition: width 0.5s ease;
}
button,
[type="button"] {
    &:focus-visible {
        outline: none !important;
    }
}
.app-header.header-shadow {
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
        0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
        0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
        0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
}

.custom-control-label {
    padding-top: 1.5px;
}

.app-sidebar__inner {
    [class*="app-sidebar__heading"] {
        height: 15.5px;
        display: flex;
        align-items: center;
    }
}
.closed-sidebar {
    [class*="app-sidebar__heading"] {
        font-size: 0.69rem !important;
        @include ellipseText();
        width: 35px;
    }
    .app-sidebar:not(:hover) ul li a {
        img {
            left: 5px !important;
        }
        svg[class*="fa"],
        i[class*="fa"] {
            left: 3.5px !important;
        }
    }
}

.app-main__inner {
    padding: 0px !important;
    height: 100%;
    position: relative;
    @include overflowYOverlay();
    .right-content {
        overflow: hidden;
        padding: 16px 10px 10px 20px !important;
        max-width: 100%;
        min-height: 100%;
    }
}
table {
    > thead > tbody {
        .dummy-row {
            &,
            tr,
            > td {
                cursor: default !important;
                border-width: 0px !important;
            }
            cursor: default !important;
        }
    }
}
table tbody {
    .row-with-innerTable {
        background-color: transparent !important;
        padding: 0px !important;
        margin: 0px;
        height: 0px !important;
        > td {
            padding: 0px !important;
            margin: 0px !important;
            height: 0px !important;
            border-top: none !important;
            overflow: hidden;
            > div.child-table-container {
                overflow: hidden;
                margin: 0px -15px;
            }
        }
    }
}
.app-page-title {
    padding: 0px !important;
    margin: 0px;

    &.no-background {
        background: none !important;
        margin-bottom: 0px !important;
    }
    .page-title-icon {
        @include box-shadow(0, 3px, 12px, $greyLightest);
    }
}
.page-title-heading {
    font-weight: 700 !important;
    color: $textDark !important;
}
.flex-space-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
