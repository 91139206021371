@import "theme/variables";

.root {
    border: 1px solid $greyLightest;
    border-radius: 1px !important;
    background-color: white;
    margin: 5px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
        font-weight: bold;
        font-size: 1.15rem;
    }
}

.cardContent,
.cardCharts {
    display: flex;
    align-items: center;
    overflow: auto;
    padding-bottom: 5px;
    flex-grow: 1;
    > * {
        margin: 5px 20px 0px 0px;
        flex-grow: 1;
        display: flex;
        height: 100%;
        max-height: 100%;
        flex-direction: column;
        width: 50%;
        max-width: 50%;
        justify-content: space-around;
    }
    .rightSection {
        padding-left: 25px;
        border-left: 1px solid $greyLightest;
    }
}
