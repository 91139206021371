@import "theme/mixins";

.root {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .createBtn {
        width: 320px;
        max-width: 320px;
    }
}
.loader {
    max-height: 60vh !important;
}
.calendarContainer {
    position: relative;
    // overflow: hidden;
    min-height: calc(100vh - 190px) !important;
    max-height: calc(100vh - 190px) !important;
    tbody.fc-body {
        td:first-child {
            .fc-row.fc-week.table-bordered {
                min-height: 20px;
            }
        }
    }

    [class*="fc-view-harness"] {
        max-height: calc(100vh - 245px) !important;
        min-height: 400px !important;
        [class*="fc-dayGridMonth-view"] [class*="fc-daygrid-body"] {
            min-height: 1000px;
            @include overflowYOverlay();
        }
    }
    &.pivotView {
        [class*="fc-view-harness"] {
            max-height: calc(100vh - 320px) !important;
            min-height: calc(100vh - 365px) !important;
        }
    }
}

.tableTitle {
    min-width: 100px;
    margin-right: 15px;
}

@media screen and (max-width: 1100px) {
    .calendarContainer {
        [class*="fc-view-harness"] {
            min-height: calc(100vh - 240px) !important;
        }
    }
}
@media screen and (max-width: 900px) {
    .calendarContainer {
        min-height: calc(100vh - 240px) !important;
        max-height: calc(100vh - 240px) !important;
        [class*="fc-view-harness"] {
            min-height: calc(100vh - 285px) !important;
            // [class*="fc-dayGridMonth-view"] [class*="fc-daygrid-body"] {
            //     min-height: 1000px;
            //     @include overflowYOverlay();
            // }
        }
        &.pivotView {
            [class*="fc-view-harness"] {
                max-height: calc(100vh - 365px) !important;
                min-height: calc(100vh - 365px) !important;
            }
        }
    }
}
@media screen and (max-width: 765px) {
    .calendarContainer {
        &.pivotView {
            overflow-y: auto;
            overflow-x: hidden;
            [class*="fc-view-harness"] {
                max-height: calc(100vh - 500px) !important;
                min-height: 400px !important;
                height: calc(100vh - 500px) !important;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .calendarContainer {
        [class*="fc-view-harness"] {
            min-height: calc(100vh - 350px) !important;
        }
        &.pivotView {
            [class*="fc-view-harness"] {
                max-height: calc(100vh - 570px) !important;
                height: calc(100vh - 570px) !important;
            }
        }
    }
}
