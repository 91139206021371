@import "theme/variables";
.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    .month {
        display: flex;
        justify-content: center;
        span {
            display: flex;
            width: 75%;
            min-width: 175px;
            padding: 3px 10px;
            background-color: $lightBlue;
            margin: 0px 20px;
            font-size: 1.2rem;
            border-radius: 4px;
            justify-content: center;
            color: $primary;
            font-weight: 900;
        }
    }
    .chart {
        width: 100%;
        display: flex;
    }
}
