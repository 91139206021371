@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-LightItalic.eot");
    src: local("Lato Light Italic"), local("Lato-LightItalic"),
        url("../../assets/fonts/lato/Lato-LightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-LightItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-LightItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Black.eot");
    src: local("Lato Black"), local("Lato-Black"),
        url("../../assets/fonts/lato/Lato-Black.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Black.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Black.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-HeavyItalic.eot");
    src: local("Lato Heavy Italic"), local("Lato-HeavyItalic"),
        url("../../assets/fonts/lato/Lato-HeavyItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-HeavyItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-HeavyItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-HeavyItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Lato Hairline";
    src: url("../../assets/fonts/lato/Lato-Hairline.eot");
    src: local("Lato Hairline"), local("Lato-Hairline"),
        url("../../assets/fonts/lato/Lato-Hairline.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Hairline.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Hairline.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Hairline.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Semibold.eot");
    src: local("Lato Semibold"), local("Lato-Semibold"),
        url("../../assets/fonts/lato/Lato-Semibold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Semibold.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Semibold.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Heavy.eot");
    src: local("Lato Heavy"), local("Lato-Heavy"),
        url("../../assets/fonts/lato/Lato-Heavy.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Heavy.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Heavy.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Heavy.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-MediumItalic.eot");
    src: local("Lato Medium Italic"), local("Lato-MediumItalic"),
        url("../../assets/fonts/lato/Lato-MediumItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-MediumItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-MediumItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Light.eot");
    src: local("Lato Light"), local("Lato-Light"),
        url("../../assets/fonts/lato/Lato-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Light.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Light.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Bold.eot");
    src: local("Lato Bold"), local("Lato-Bold"),
        url("../../assets/fonts/lato/Lato-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Bold.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Bold.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-BoldItalic.eot");
    src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
        url("../../assets/fonts/lato/Lato-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-BoldItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-BoldItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Regular.eot");
    src: local("Lato Regular"), local("Lato-Regular"),
        url("../../assets/fonts/lato/Lato-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Regular.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Regular.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato Hairline";
    src: url("../../assets/fonts/lato/Lato-HairlineItalic.eot");
    src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"),
        url("../../assets/fonts/lato/Lato-HairlineItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-HairlineItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-HairlineItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-HairlineItalic.ttf")
            format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-SemiboldItalic.eot");
    src: local("Lato Semibold Italic"), local("Lato-SemiboldItalic"),
        url("../../assets/fonts/lato/Lato-SemiboldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-SemiboldItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-SemiboldItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-SemiboldItalic.ttf")
            format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-BlackItalic.eot");
    src: local("Lato Black Italic"), local("Lato-BlackItalic"),
        url("../../assets/fonts/lato/Lato-BlackItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-BlackItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-BlackItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Italic.eot");
    src: local("Lato Italic"), local("Lato-Italic"),
        url("../../assets/fonts/lato/Lato-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Italic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Italic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-ThinItalic.eot");
    src: local("Lato Thin Italic"), local("Lato-ThinItalic"),
        url("../../assets/fonts/lato/Lato-ThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-ThinItalic.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-ThinItalic.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Thin.eot");
    src: local("Lato Thin"), local("Lato-Thin"),
        url("../../assets/fonts/lato/Lato-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Thin.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Thin.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/Lato-Medium.eot");
    src: local("Lato Medium"), local("Lato-Medium"),
        url("../../assets/fonts/lato/Lato-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/lato/Lato-Medium.woff2") format("woff2"),
        url("../../assets/fonts/lato/Lato-Medium.woff") format("woff"),
        url("../../assets/fonts/lato/Lato-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
