@import "theme/variables";

.root {
    width: 100%;
    min-height: 65px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    [class*="card-body"] {
        padding: 15px 20px;
    }
    > * {
        width: 100%;
    }
}
.rootDiv {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    justify-content: space-between;
    width: 100%;
    .infoSection {
        flex-grow: 1;
    }
    .menuSection {
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
.dropDownMenuButton {
    .menuCard {
        width: 260px !important;
    }
}
.menuIcon {
    width: 22px;
    height: auto;
    object-fit: contain;
    margin: 0px;
}
