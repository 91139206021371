@import "theme/variables";

.root {
    position: relative;
    > :first-child {
        padding: 0px !important;
    }
}
.trigger {
    cursor: pointer;
}
.icon {
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
}
