@import "theme/variables";

.root {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .contentBody {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .indexHeader {
        height: $messageHeaderHeight;
        padding-left: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        .headerDelete {
            display: flex;
            flex-direction: row;
            align-items: center;
            .checkBox {
                input {
                    width: 15px;
                    height: 15px;
                    &:checked + span {
                        background-color: $primary;
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                flex-direction: row;
                cursor: pointer;
                .iconDelete {
                    cursor: pointer;
                    margin: 0 15px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .deleteText {
                    color: $primary;
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: $primary;
                }
            }
        }
    }
    .indexBody {
        width: 100%;
        height: calc(100vh - 290px);
        padding: 0px 11px;
        max-height: 100%;
        flex-grow: 1;
        min-width: 400px;
        padding-bottom: 10px;
        overflow: auto;
        .infiniteScroll {
            overflow: hidden !important;
        }
    }
}
@media screen and (max-width: 900px) {
    .root {
        .indexBody {
            height: calc(100vh - 345px);
            min-height: 320px !important;
        }
    }
}
