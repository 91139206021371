@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin overflowYOverlay {
    overflow-y: overlay;
    overflow-x: hidden;
    @-moz-document url-prefix() {
        // for firefox hide scrollbar
        overflow-y: auto !important;
    }
}
@mixin overflowXOverlay {
    overflow-x: overlay;
    overflow-y: hidden;
    @-moz-document url-prefix() {
        // for firefox hide scrollbar
        overflow-x: auto !important;
    }
}
@mixin overflowOverlay {
    overflow: overlay;
    @-moz-document url-prefix() {
        // for firefox hide scrollbar
        overflow: auto !important;
    }
}

@mixin ellipseText {
    display: block;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap !important;
    text-overflow: ellipsis;
}
@mixin ellipseText2Line {
    &,
    span,
    p {
        &:not(td, th, tr) {
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            word-break: break-all;

            @supports (-webkit-line-clamp: 2) {
                white-space: initial !important;
                -webkit-line-clamp: 2;
                display: -webkit-box !important;
                -webkit-box-orient: vertical !important;
            }
        }
    }
}
@mixin blur($amount) {
    // has to be enabled in firefox manually
    // https://dev.to/snkds/how-to-enable-backdrop-filter-in-firefox-2n8e
    backdrop-filter: blur($amount);
    -webkit-backdrop-filter: blur($amount);
}

@mixin flex {
    display: flex;
}
@mixin flexColumn {
    @include flex();
    flex-direction: column;
}

@mixin verticallyCenter {
    @include flex();
    align-items: center;
}
@mixin flexCenter {
    @include flex();
    justify-content: center;
    align-items: center;
}

@mixin flexColumnReverse {
    @include flex();
    flex-direction: column-reverse;
}
