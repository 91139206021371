// checkbox
.custom-control.custom-checkbox {
    padding-left: 1.75rem !important;
    input {
        width: 100% !important;
        height: 100% !important;
    }
    .custom-control-label {
        &::before,
        &::after {
            width: 1.1rem !important;
            height: 1.1rem !important;
        }
    }
    .custom-control-input {
        cursor: pointer;
        z-index: 1;

        &:checked ~ .custom-control-label {
            &::before {
                border-color: $primary;
                background-color: $primary;
            }
        }
    }
}
.custom-switch {
    [class*="custom-control-input"]:disabled {
        & ~ [class*="custom-control-label"] {
            &::before {
                background-color: $greyAccent !important;
                border-color: $greyAccent !important;
            }
            &::after {
                background-color: #adb5bd !important;
            }
        }
    }
}
.blue-toggle .custom-switch {
    [class*="custom-control-input"]:checked {
        & ~ [class*="custom-control-label"] {
            &::before {
                background-color: $primaryDark !important;
                border-color: $primaryDark !important;
            }
        }
    }
}
.green-toggle .custom-switch {
    [class*="custom-control-input"]:checked {
        & ~ [class*="custom-control-label"] {
            &::before {
                background-color: $darkGreen !important;
                border-color: $darkGreen !important;
            }
        }
    }
}
.red-toggle .custom-switch {
    [class*="custom-control-input"]:checked {
        & ~ [class*="custom-control-label"] {
            &::before {
                background-color: $red !important;
                border-color: $red !important;
            }
        }
    }
}
