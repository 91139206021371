@import "theme/variables";
@import "theme/mixins";

.imageContainer {
    margin-right: 15px;
    position: relative;
    border-radius: 5px;
    img {
        width: 150px;
        height: 100px;
        transition: 0.5s ease;
        // backface-visibility: hidden;
        border-radius: 5px;
        object-fit: contain;
    }
    .animateDiv {
        width: 150px;
        height: 100px;
        display: flex;
        flex-direction: row;
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 0;
        text-align: bottom;
        background-color: $blueDimmed;
        border-radius: 5px;
        padding-left: 10px;
        padding-bottom: 10px;
        color: white;
        .imageDes {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            font-size: 0.8rem;
            .imageName {
                margin: 0px;
                padding-bottom: 1px;
            }
        }
        .download {
            display: flex;
            margin-left: auto;
            padding: 0 10px;
            padding-bottom: 7px;
            justify-content: flex-end;
            align-items: flex-end;
            a {
                color: white;
                cursor: pointer;
                img {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }
            }
        }
    }

    &:hover .image {
        opacity: 0.5;
    }
    &:hover .animateDiv {
        opacity: 0.7;
    }
}
