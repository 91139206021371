@import "theme/variables";

.icon {
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    margin: 0.4rem;
    &.pinkIcon {
        background-color: $darkPink;
    }
    &.blueIcon {
        background-color: $primary;
    }
}
