@import "theme/variables";
.root {
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: auto;
    padding: 0px;
    right: 20px;
    bottom: 30px;
    margin: 0px;
    z-index: 10;
    .floatingBtn {
        border-radius: 50%;
        border-color: transparent;
        width: 75px;
        height: 75px;
        padding: 5px;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        margin: 0px 10px;
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            &.save-icon {
                transform: scale(0.8);
                -webkit-transform: scale(0.8);
            }
        }
        i,
        svg {
            font-size: 2.5rem;
        }
        &:not([class*="green-btn"]) {
            border-color: $primary;
            border-width: 5px;
            background-color: white;
            &:hover,
            &:active,
            &:focus {
                background-color: $greyLighter;
                border-color: $primary !important;
            }
            &:disabled {
                background-color: $darkGrey !important;
                opacity: 0.8;
            }
        }
    }
}
