@import "theme/variables";
.root {
    display: flex;
    flex-direction: column;
    > span {
        // display: block;
        margin-bottom: 8px;
    }
    > div:first-child {
        width: 100%;
    }
}
.btn {
    width: 100px;
}
.switch {
    margin-bottom: 5px !important;
}
