@import "theme/variables";
@import "theme/mixins";

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    min-width: 100%;
    > div {
        width: 100%;
        padding: 4px 0px;
    }
    .content {
        > div {
            display: flex;

            > table {
                flex-grow: 1;
                width: 100%;
            }
        }
    }

    // @media screen and (min-width: 2500px) {
    //     table {
    //         max-width: calc(100vw - 400px) !important;
    //     }
    // }
}
