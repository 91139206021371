@import "theme/variables";
.root {
    margin-top: 5px;
    justify-self: flex-end;
    font-size: 1rem;
    .totalText {
        color: $textGrey;
    }
    .countText {
        margin-left: 5px;
        font-weight: 600;
    }
}
