@import "theme/variables";
@import "theme/mixins";

.card {
    @include flexColumn();
    min-width: 350px;
    width: 350px;
    min-height: 400px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: white;
    border: 1px solid $borderGrey;
    padding: 20px;

    > div {
        @include flexColumn();
        align-items: flex-start;
        > * {
            display: flex;
            width: 100%;
            align-items: center;
        }
    }

    .header {
        position: relative;

        .basicPlanWithTag {
            display: flex;
            justify-content: space-between;
            height: 45px;
            .basicPlan {
                display: flex;
                font-size: 30px;
            }
            .tagImage {
                width: 42px;
                height: 42px;
                object-fit: contain;
            }
        }

        .perMonthCostDiv {
            height: 45px;
            display: flex;
            align-items: flex-end;
            .monthText {
                font-weight: 400;
                font-size: 16px;
                color: $textDark;
            }
        }
    }

    .body {
        flex-grow: 1;
        overflow: hidden;
        justify-content: space-between;
        .content {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .description {
                width: 100%;
                font-size: 13px;
                color: $textGrey;
                margin-bottom: 20px;
            }

            .modules {
                margin: 10px 0;
                min-height: 120px;
                flex-direction: column;
                align-items: flex-start;

                .moduleItem {
                    display: flex;
                    padding: 5px 0;
                    .check {
                        margin-right: 18px;
                    }
                }
            }
        }
        .btnSkelton {
            border-radius: 25px;
        }
    }
}

@media screen and (max-width: 768px) {
    .card {
        width: 100%;
        margin-right: 15px;
    }
}
