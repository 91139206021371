@import "theme/variables";
@import "theme/mixins";

.trigger {
    cursor: pointer !important;
}
.cardRoot {
    max-height: calc(min(800px, 60vh));
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
}
.menuCard {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 2px 5px 3px;
    text-transform: none !important;
    // @include box-shadow(0, 1.5px, 15px, $greyLightest);
    box-shadow: 0 1.5px 15px #dfdfdf;
}
