@import "theme/variables";
.disabled {
    &,
    * {
        cursor: not-allowed;
    }

    // [class*="custom-switch custom-control"]
    //     > [class*="custom-control-label"]::before {
    //     background-color: $greyLight !important;
    //     border-color: $greyLight !important;
    // }
}
