@import "theme/mixins";
.marginRight {
    padding-right: 70px;
}

.height42 {
    height: 42px !important;
}
.appIconWithName {
    @include ellipseText();
    max-width: 153px;
}
@media screen and (max-width: 1400px) {
    .marginRight {
        padding-right: 30px;
    }
}

@media screen and (min-width: 2500px) {
    .appIconWithName {
        max-width: 253px !important;
    }
}
